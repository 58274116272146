import { useCallback, useEffect, useRef, useState } from 'react';
import { Account, Pagination } from '../types';
import { getAccounts } from '../api/apiWorker';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  manualFetch?: boolean | undefined;
}

export const useAdminDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState<Account | null>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const isFirstRequest = useRef(true);

  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: null,
    totalPages: 1
  });
  const fetchAccounts = async () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page') || 1;
    setLoading(true);
    try {
      let queryParam = `?page=${page}`;
      if (search) {
        queryParam += `&search_text=${search}`;
      }
      const { data } = await getAccounts({ params: queryParam });
      const totalPages = Math.ceil(data.total / data.page_size);
      setPagination(prev => ({ ...prev, page: data.page, pageSize: data.page_size, totalPages: totalPages }));
      setAccounts(data || null);
      isFirstRequest.current = false;
    } catch (e) {
      message.error('Failed to fetch accounts');
      console.error('error:', e);
    }
    setLoading(false);
  };

  const fetchAccountssByParams = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    params.set('page', '1');
    navigate({ search: params.toString() });
    if (page === '1') {
      fetchAccounts();
    }
  };

  useEffect(() => {
    if (!isFirstRequest.current) {
      fetchAccountssByParams();
    }
  }, [search]);

  useEffect(() => {
    fetchAccounts();
  }, [location.search]);

  return {
    accounts,
    loading,
    pagination,
    search,
    setSearch,
    setAccounts,
    fetchAccounts
  };
};
