import React, { useEffect, useState } from 'react';
import { CreateAccountBtnWrapper, PageContainer, PageContent, PageWrapper, StyledButton } from '../../assets/styles/Styled';
import { Link, useLocation } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { Search } from '../../components/Search';
import { FilterPanel } from './FilterPanel';
import { ScriptsTable } from './ScriptsTable';
import { AddScriptIcon, AllFilesIcon, BackArrowIcon, BasketIcon, FolderAddIcon, FolderIcon, ShareIcon } from '../../assets/icons';
import { ScriptItemMobileView } from './ScriptItemMobileView';
import { BreadCrumbs, FilterPanelItems, FilterPanelWrapper, Tab, TabsWrapper } from './scriptPage.styled';
import { useNavigate } from 'react-router-dom';
import { ButtonAddScriptToFolder, EmptyStateContainer } from './ScriptStyles';
import { useFilterResource, usePopups, UseResource } from './hooks';
import { Tabs } from './hooks/useResource';
import PopupsManager from './popups/PopupManager';
import { Resource } from '../../types';
import ReactPaginate from 'react-paginate';
import useMedia from '../../hooks/useMedia';
const ScriptsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { popupsState, togglePopup } = usePopups();
  const { isMobile } = useMedia({ mobileMaxWidth: 767 });

  const {
    resources,
    activeTab,
    loading,
    scriptOrganizations,
    selectedFolder,
    pagination,
    setSearch,
    setActiveTab,
    fetchResources,
    handleRemove,
    handleMoveScripts,
    handleBackToAllScripts,
    handleAggreeMoveScripts,
    setRequestDataMoveFiles
  } = UseResource({
    togglePopup: togglePopup
  });

  const { filteredScripts, setFilters, setSortOption, filters } = useFilterResource({ resources: resources });

  const [toggleSearch, setToggleSearch] = useState(false);

  const handleSetActiveTab = (tab: Tabs) => {
    const params = new URLSearchParams(location.search);
    params.set('page', '1');
    navigate({ search: params.toString() });
    setActiveTab(tab);
  };

  const handleDeleteFolder = () => {
    togglePopup({
      popupName: 'showPopupDeleteFolder',
      value: true
    });
  };

  const handleCreateFolder = () => {
    togglePopup({
      popupName: 'showPopupCreateFolder',
      value: true
    });
  };

  const handleRowDoubleClick = (record: Resource) => {
    if (record.resource_type === 'folder') {
      const folderId = record.resource_id;
      navigate(`?folderId=${folderId}`);
      setActiveTab('allFiles');
    }
  };

  const handleAddScriptsToFolder = () => {
    togglePopup({
      popupName: 'showPopupSelectScript',
      value: true
    });
  };
  const handlePageClick = (event: { selected: number }) => {
    const params = new URLSearchParams(location.search);

    params.set('page', (event.selected + 1).toString());
    navigate({ search: params.toString() });
  };

  return (
    <PageWrapper>
      <PageContainer>
        <PageContent>
          <PopupsManager
            popupsState={popupsState}
            togglePopup={togglePopup}
            setActiveTab={setActiveTab}
            handleRemove={handleRemove}
            handleMoveScripts={handleMoveScripts}
            handleAggreeMoveScripts={handleAggreeMoveScripts}
            setRequestDataMoveFiles={setRequestDataMoveFiles}
            resourceType="script"
            resourse={selectedFolder}
          />
          <Space direction="vertical" size={24} className="w-100">
            <CreateAccountBtnWrapper className="btn-script">
              <Link to={selectedFolder?.resource_id ? `/scripts/new?parentId=${selectedFolder.resource_id}` : '/scripts/new'}>
                <StyledButton className="btn-green">Create Script</StyledButton>
              </Link>
            </CreateAccountBtnWrapper>
            <FilterPanelWrapper>
              <BreadCrumbs isActiveSearch={toggleSearch}>
                <span
                  onClick={selectedFolder?.resource_id ? handleBackToAllScripts : () => undefined}
                  className={selectedFolder?.resource_id ? 'cursor-pointer' : 'cursor-default'}
                >
                  {selectedFolder?.resource_id && <BackArrowIcon />}

                  {!selectedFolder?.resource_id && (
                    <span className={`${selectedFolder?.resource_name ? 'light-description' : 'primary-text'}`}>
                      Available Scripts {selectedFolder?.resource_name && '/'}{' '}
                    </span>
                  )}
                </span>

                {selectedFolder?.resource_name && <span className="cursor-default">{selectedFolder?.resource_name}</span>}
              </BreadCrumbs>

              <FilterPanelItems>
                <FilterPanel
                  organizations={scriptOrganizations}
                  onSort={opt => setSortOption(opt)}
                  onFilterChange={filter => setFilters(prev => ({ ...prev, filter }))}
                  isShowSortPanel={false}
                />
                <Search
                  className={`${toggleSearch ? 'search-visible' : 'search-hide'}`}
                  placeholder=""
                  onChange={search => setSearch(search)}
                  setToggleSearch={setToggleSearch}
                />
                {selectedFolder?.resource_id ? (
                  <>
                    <AddScriptIcon
                      className={`folder-icon ${!selectedFolder.write_access ? 'disabled' : ''}`}
                      onClick={handleAddScriptsToFolder}
                    />
                    <ShareIcon
                      className={`folder-icon ${!selectedFolder.write_access ? 'disabled' : ''}`}
                      onClick={() =>
                        togglePopup({
                          popupName: 'showPopupSharedFolder',
                          value: true
                        })
                      }
                    />
                    <BasketIcon
                      className={`folder-icon ${!selectedFolder.write_access ? 'disabled' : ''}`}
                      onClick={handleDeleteFolder}
                    />
                  </>
                ) : (
                  <FolderAddIcon className="folder-icon" onClick={handleCreateFolder} />
                )}
              </FilterPanelItems>
            </FilterPanelWrapper>
            <TabsWrapper>
              <Tab className={activeTab === 'allFiles' ? 'active' : ''} onClick={() => handleSetActiveTab('allFiles')}>
                <AllFilesIcon />
                All files
              </Tab>
              <Tab
                className={`${activeTab === 'folders' ? 'active' : ''} ${selectedFolder?.resource_id && 'disabled'}`}
                onClick={() => handleSetActiveTab('folders')}
              >
                <FolderIcon />
                Folders
              </Tab>
            </TabsWrapper>
          </Space>
          {selectedFolder && !!!filteredScripts.length ? (
            <>
              <div className="hide-lg">
                <ScriptsTable tableData={[]} loading={loading} isHideBody={true} withPagination={false} />
              </div>

              <EmptyStateContainer>
                <Typography.Paragraph className="hint-color" style={{ fontSize: '14px', fontWeight: '800' }}>
                  This folder is empty. Let`s add something!
                </Typography.Paragraph>
                <ButtonAddScriptToFolder disabled={loading} onClick={handleAddScriptsToFolder}>
                  Move Files
                </ButtonAddScriptToFolder>
              </EmptyStateContainer>
            </>
          ) : (
            <>
              <div className="hide-lg">
                <ScriptsTable
                  tableData={filteredScripts}
                  loading={loading}
                  withPagination={pagination.totalPages > 1}
                  onRemove={handleRemove}
                  fetchResources={fetchResources}
                  handleRowDoubleClick={handleRowDoubleClick}
                  handleMoveScripts={handleMoveScripts}
                />
              </div>
              <div className="hide-sm">
                <ScriptItemMobileView
                  resourse={filteredScripts}
                  loading={loading}
                  onRemove={handleRemove}
                  fetchResources={fetchResources}
                  handleRowDoubleClick={handleRowDoubleClick}
                  handleMoveScripts={handleMoveScripts}
                />
              </div>
              {pagination.page && pagination.totalPages > 1 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={`${isMobile ? '' : 'next '}>`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={isMobile ? 1 : 3}
                  marginPagesDisplayed={isMobile ? 1 : 2}
                  pageCount={pagination.totalPages}
                  previousLabel={`<${isMobile ? '' : ' previous'}`}
                  renderOnZeroPageCount={null}
                  className="pagination"
                  forcePage={pagination.page - 1}
                />
              )}
            </>
          )}
        </PageContent>
      </PageContainer>
    </PageWrapper>
  );
};

export default ScriptsPage;
