import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { AccountsIcon, BearIcon, BuildsIcon, OrganizationsIcon, ScriptsIcon } from '../../assets/icons';
import { Tooltip, Typography } from 'antd';
import { useAppContext } from '../../context/AppContext';
import { SettingOutlined } from '@ant-design/icons';

const NavbarContainer = styled.div`
  z-index: 10;
  height: 80px;
  min-height: 80px;
  width: 100%;
  padding: 0 51px;
  display: grid;
  grid-template-columns: 120px auto;
  align-items: center;
  background-color: var(--light-gray);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);

  #logout-btn {
    justify-self: start;
  }

  @media (max-width: 1168px) {
    padding: 0 35px;
    grid-template-columns: 60px auto;
  }

  @media (max-width: 1120px) {
    grid-template-columns: 1fr;
    height: 160px;
    min-height: 160px;
    padding: 0 20px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    height: 220px;
    min-height: 220px;
  }

  @media (max-width: 370px) {
    padding: 0 10px;
  }
`;

const MenuItemsWrapper = styled.div`
  display: grid;
  max-width: 340px;
  grid-template-columns: repeat(2, 170px);
  background-color: #ffffff;
  border-radius: 32px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
`;

const MenuItem = styled(Link)<{ isActive: boolean; withShadow?: boolean; disabled?: boolean }>`
  width: auto;
  height: 52px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: ${props => (props.isActive ? '2px solid var(--primary-color)' : 'none')};
  border-radius: 32px;
  background-color: #fff;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
  color: ${props => (props.disabled ? 'var(--bg-basic)' : props.isActive ? 'var(--primary-color)' : 'var(--hint-color)')};
  font-weight: 600;
  box-shadow: ${props => (props.withShadow ? '0 4px 14px 0 rgba(0, 0, 0, 0.07)' : 'none')};

  &:hover {
    color: ${props => (props.disabled ? 'var(--bg-basic)' : 'var(--primary-color)')};
  }

  .anticon {
    transform: translateY(-2px);
  }
  &.show-sm {
    display: none;
  }
  @media (max-width: 1120px) {
    &.hide-sm {
      display: none;
    }

    &.show-sm {
      display: flex;
    }
  }

  @media (max-width: 820px) {
    padding: 0 26px;
  }
`;

const LogoutWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  article {
    transition: all 150ms ease;

    &:hover {
      color: var(--primary-color) !important;
    }
  }
`;

const CenterButtonGroup = styled.div`
  justify-self: center;
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const RightButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (max-width: 800px) {
    gap: 8px;
  }
`;

const HideTextSm = styled.span`
  @media (max-width: 1120px) {
    display: none;
  }
`;

const Navbar = () => {
  const location = useLocation();
  const { logout, user } = useAuth();
  const { accountsResource, organizations } = useAppContext();
  const isAdmin = user?.user.role === 'admin';
  const allowAccounts = isAdmin || !!accountsResource?.length;
  const allowOrganizations = isAdmin || !!organizations.length;

  const isActive = (paths: string[]) => {
    return paths.some(path => location.pathname.startsWith(path));
  };

  return (
    <NavbarContainer>
      <LogoutWrapper id="logout-btn">
        <Typography className="bold-16 hint-color cursor-pointer" onClick={logout}>
          Log Out
        </Typography>
      </LogoutWrapper>

      <CenterButtonGroup id="center-btn-container">
        {isAdmin && (
          <MenuItem
            disabled={!allowOrganizations}
            onClick={e => !allowOrganizations && e.preventDefault()}
            withShadow
            isActive={isActive(['/characters'])}
            to="/characters"
            className="hide-sm"
          >
            <BearIcon />
            Characters
          </MenuItem>
        )}
        <MenuItemsWrapper>
          <Tooltip
            color="#ffffff"
            title={
              !allowAccounts && (
                <Typography className="extrabold-14 hint-color text-align-center">
                  Only administrators can create and view accounts
                </Typography>
              )
            }
          >
            <MenuItem
              disabled={!allowAccounts}
              onClick={e => !allowAccounts && e.preventDefault()}
              isActive={isActive(['/admin', '/user-registration'])}
              to="/admin"
            >
              <AccountsIcon />
              Accounts
            </MenuItem>
          </Tooltip>
          <MenuItem isActive={isActive(['/scripts'])} to="/scripts">
            <ScriptsIcon />
            Scripts
          </MenuItem>
        </MenuItemsWrapper>
        <RightButtonGroup>
          <Tooltip
            color="#ffffff"
            title={
              !allowOrganizations && (
                <Typography className="extrabold-14 hint-color text-align-center">
                  Only administrators can create and view organizations
                </Typography>
              )
            }
          >
            <MenuItem
              disabled={!allowOrganizations}
              onClick={e => !allowOrganizations && e.preventDefault()}
              withShadow
              isActive={isActive(['/organizations'])}
              to="/organizations"
            >
              <OrganizationsIcon />
              <HideTextSm>Organizations</HideTextSm>
            </MenuItem>
          </Tooltip>
          {isAdmin && (
            <MenuItem
              disabled={!allowOrganizations}
              onClick={e => !allowOrganizations && e.preventDefault()}
              withShadow
              isActive={isActive(['/version'])}
              to="/version"
            >
              <BuildsIcon />
              <HideTextSm>Builds</HideTextSm>
            </MenuItem>
          )}
          {isAdmin && (
            <MenuItem
              disabled={!allowOrganizations}
              onClick={e => !allowOrganizations && e.preventDefault()}
              withShadow
              isActive={isActive(['/characters'])}
              to="/characters"
              className="show-sm"
            >
              <BearIcon />
              Characters
            </MenuItem>
          )}
          {/*<MenuItem id="builds-btn" withShadow isActive={false} to="/builds">*/}
          {/*  <BuildsIcon />*/}
          {/*  Builds*/}
          {/*</MenuItem>*/}
        </RightButtonGroup>
      </CenterButtonGroup>
    </NavbarContainer>
  );
};

export default Navbar;
