import React, { useEffect, useState } from 'react';
import { CheckboxProps, Form, Typography } from 'antd';
import { FormLabel } from '../../../../components/Antd/FormLabel';
import { useScriptForm } from '../../ScriptCreateStore';
import styled from 'styled-components';
import { PageContent } from '../../../../assets/styles/Styled';
import { StyledInput, CustomCheckbox } from '../sections.styled';
import useMedia from '../../../../hooks/useMedia';
import { SharedForm } from '../../../../components/SharedForm/SharedForm';

const Container = styled(PageContent)`
  margin: 0 auto;
  overflow-y: auto;
`;

const ShareFormWrapper = styled.div`
  background: var(--light-gray);
  padding: 16px 8px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const NameAndShareSection = () => {
  const [allowDuplicate, setAllowDuplicate] = useState(false);

  const { formValues, setFormValues, setSharingList, sharingList } = useScriptForm();
  const [form] = Form.useForm();

  const { isMobile } = useMedia();

  useEffect(() => {
    form.setFieldsValue(formValues);
    setAllowDuplicate(formValues.copy_allowed || false);
  }, [form, formValues]);

  const onChange: CheckboxProps['onChange'] = e => {
    setFormValues(prev => ({ ...prev, copy_allowed: e.target.checked }));
    setAllowDuplicate(e.target.checked);
  };

  return (
    <Container>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={changedValues => setFormValues(prev => ({ ...prev, ...changedValues }))}
      >
        <Form.Item
          label={<FormLabel text="Enter script name" />}
          name="resource_name"
          rules={[{ required: true, message: 'Script name is required' }]}
        >
          <StyledInput size="large" placeholder="Enter script name" />
        </Form.Item>
        <FormLabel text="Sharing Script" />
      </Form>
      <ShareFormWrapper>
        <SharedForm
          setSharingList={setSharingList}
          // setAllowDuplicate={setAllowDuplicate}
          allowDuplicate={allowDuplicate}
          sharingList={sharingList}
        />
      </ShareFormWrapper>

      <CustomCheckbox onChange={onChange} checked={allowDuplicate}>
        <Typography className="regular-16 light-description fnt-mulish">
          Allow to duplicate script to those who have access
        </Typography>
      </CustomCheckbox>
    </Container>
  );
};
