import styled from 'styled-components';
import { inputStyles, SharingButton, StyledCustomFormTag, StyledLabel } from '../../assets/styles/Styled';
import { Checkbox, Select } from 'antd';

export const SelectOptionsUser = styled(StyledLabel)``;
export const StyledFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 16px;
  align-items: end;
  margin-bottom: 8px;
`;

export const ItemFieldsWrapper = styled(StyledFieldsWrapper)`
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: #afccd2 !important;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  .ant-select-selection-search > input {
    color: #062e3a !important;
    font-size: 16px !important;
    padding: 0 16px;
  }
  .ant-select-selector {
    ${inputStyles}
    border-color:  transparent !important;

    &:hover {
      border-color: #9f6bff !important;
    }
  }
  .ant-select-selection-item {
    align-items: center !important;
    height: 44px !important;
  }
  .ant-select-selection-item-remove {
    font-size: 18px !important;
    color: #afccd2 !important;
  }
  .ant-select-arrow {
    display: none;
  }
  &.ant-select-disabled {
    .ant-select-selector {
      background: #f5fbfc !important;
    }
  }
`;

export const SelectRestrictions = styled(Select)`
  &.select-shared-restrictions {
    height: 35px;
    width: 101px;
    position: absolute;
    bottom: 8px;
    right: 16px;

    & .ant-select-selector {
      background: #f5fbfc;
      color: var(--light-description);
      border: 1px solid var(--light-description);
      border-radius: 8px;
    }
  }

  &.no-arrow {
    pointer-events: none;
    .ant-select-arrow {
      display: none;
    }
    & .ant-select-selector {
      .ant-select-selection-item {
        padding-inline-end: 0 !important;
        text-align: center;
        font-weight: 700;
      }
    }
  }
`;

export const SelectRestrictionsLabel = styled.span`
  color: var(--light-description);
  font-size: 12px;
`;

export const StyledSharingButton = styled(SharingButton)`
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const SharingList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const CustomTag = styled(StyledCustomFormTag)`
  gap: 5px;
  span {
    height: unset;
  }
  &.tag-disabled {
    background: transparent;
    border-color: transparent;
  }
`;

export const StyledTagRestriction = styled.span`
  font-family: 'Mulish', sans-serif;
  font-size: 12px;
  font-weight: 700;
`;

export const CustomCheckbox = styled(Checkbox)`
  width: 100%;
  .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #7da1a9 !important;
    color: #7da1a9 !important;
    &::after {
      border-color: #7da1a9 !important;
    }
  }
  @media (max-width: 450px) {
    margin-top: 28px;
    margin-bottom: 38px;

    text-align: left;

    > span {
      > article {
        line-height: 16px;
      }
    }
  }
`;
