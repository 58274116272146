import { Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditBtn } from '../../assets/styles/Styled';
import styled from 'styled-components';
import { ChangeVersionFormLabel } from '../../components/Antd/FormLabel';
import { ChangeWebAppVersionsFormValues, IWebConfigN } from '../../types';
import { isEqual } from 'lodash';
import { HeaderText } from './ChangeVersion';

interface IChangeWebAppVersionFormProps {
  builds_download_urls?: IWebConfigN;
  goiteens_builds_download_urls?: IWebConfigN;
  configId: 'builds_download_urls' | 'goiteens_builds_download_urls';

  updateWebAppConfig: ({
    configId,
    newConfig
  }: {
    configId: 'builds_download_urls' | 'goiteens_builds_download_urls';
    newConfig: ChangeWebAppVersionsFormValues;
  }) => void;
}

interface IFormWebApp {
  builds_download_urls: IWebConfigN;
}
interface IFormGoITeens {
  goiteens_builds_download_urls: IWebConfigN;
}

const Container = styled.div`
  width: 100%;
  padding: 0 12px;
`;
export const ChangeWebAppVersionForm: React.FC<IChangeWebAppVersionFormProps> = ({
  configId,
  builds_download_urls,
  goiteens_builds_download_urls,
  updateWebAppConfig
}) => {
  const [isDisabledWebApp, setIsDisabledWebApp] = useState<boolean>(true);

  const [webAppForm] = Form.useForm<IFormWebApp | IFormGoITeens>();

  const initialValuesWebApp = configId === 'builds_download_urls' ? { builds_download_urls } : { goiteens_builds_download_urls };

  const formWebAppWatch = Form.useWatch(
    configId === 'builds_download_urls' ? 'builds_download_urls' : 'goiteens_builds_download_urls',
    webAppForm
  );

  useEffect(() => {
    if (configId === 'builds_download_urls') {
      webAppForm.setFieldsValue({ builds_download_urls: builds_download_urls });
    }
    webAppForm.setFieldsValue({ goiteens_builds_download_urls: goiteens_builds_download_urls });
  }, [builds_download_urls, goiteens_builds_download_urls]);

  useEffect(() => {
    const isDisabled =
      configId === 'builds_download_urls'
        ? isEqual(builds_download_urls, formWebAppWatch)
        : isEqual(goiteens_builds_download_urls, formWebAppWatch);
    setIsDisabledWebApp(isDisabled);
  }, [formWebAppWatch]);

  const validateFormat = (_: any, value: string) => {
    const regex = /^\d+\.\d+\.\d+$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error('Version must be in the format X.X.X'));
    }
    return Promise.resolve();
  };

  const handleSubmitConfig = (values: IFormWebApp | IFormGoITeens) => {
    updateWebAppConfig({
      configId: configId,
      newConfig: values
    });
    setIsDisabledWebApp(true);
  };

  const formStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px'
  };
  return (
    <Container>
      <Form
        name="basic"
        className="w-100"
        layout="vertical"
        initialValues={initialValuesWebApp}
        style={formStyle}
        form={webAppForm}
        onFinish={handleSubmitConfig}
        requiredMark={false}
      >
        <Form.Item
          label={<ChangeVersionFormLabel text="Update url windows" />}
          name={[configId, 'windows']}
          rules={[{ required: true, message: 'Please Enter new url' }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update url windows" size="large" />
        </Form.Item>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update url macos" />}
          name={[configId, 'macos']}
          rules={[{ required: true, message: 'Please Enter new url' }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update url macos" size="large" />
        </Form.Item>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update client version" />}
          name={[configId, 'client_version']}
          rules={[{ required: true, message: 'Please Enter new version' }, { validator: validateFormat }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update client version" size="large" />
        </Form.Item>
        <EditBtn className="btn-orange w-100  mr-0 mt-0 mb-4" type="submit" disabled={isDisabledWebApp}>
          Apply
        </EditBtn>
      </Form>
    </Container>
  );
};
