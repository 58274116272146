import React, { useMemo, useState } from 'react';
import { FilterOptions, Resource, SortOptions } from '../../../types';
import { FilterItems } from '../FilterPanel';

interface Props {
  resources: Resource[];
}

interface ReturnProps {
  filteredScripts: Resource[];
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOptions>>;
}

export interface Filters {
  filter: FilterItems;
}

export const useFilterResource = ({ resources }: Props): ReturnProps => {
  const [filters, setFilters] = useState<Filters>({
    filter: { option: FilterOptions.all, selectedOrganizations: null }
  });

  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.newest);

  const filteredScripts: Resource[] = useMemo(() => {
    let resultResources = resources;

    const { option, selectedOrganizations } = filters.filter;
    if (option === FilterOptions.withoutOrganization) {
      resultResources = resultResources.filter(script => !script.organization_names?.length);
    }
    if (selectedOrganizations?.length) {
      resultResources = resultResources.filter(script => {
        return script.organization_names?.some(org => selectedOrganizations.includes(org));
      });
    }

    return resultResources;
  }, [resources, filters, sortOption]);
  return { filteredScripts, filters, setFilters, setSortOption };
};
