import React, { useCallback, useState } from 'react';
import { Dropdown, MenuProps, Modal, Tooltip, Typography } from 'antd';
import { DropdownIcon } from '../../assets/icons';
import { IDeleteResource, IMoveScriptsData, Resource } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';

import { usePopups } from './hooks';
import PopupsManager from './popups/PopupManager';

interface Props {
  resourse: Resource;
  menuItemsProps?: MenuProps['items'];
  onRemove?: (data: IDeleteResource) => void;
  fetchResources?: () => Promise<void>;
  handleMoveScripts?: ({ folderId, scripts }: IMoveScriptsData) => Promise<void>;
}

export const DropDown: React.FunctionComponent<Props> = ({
  resourse,
  menuItemsProps,
  onRemove,
  fetchResources,
  handleMoveScripts
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { popupsState, togglePopup } = usePopups();

  const [selectedResourceType, setSelectedResourceType] = useState<'folder' | 'script'>('script');

  const params = new URLSearchParams(location.search);
  const folderId = params.get('folderId');

  const menuItems: MenuProps['items'] =
    resourse.resource_type !== 'folder'
      ? [
          {
            label: (
              <Typography className={`normal-16 text-secondary fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Edit script
              </Typography>
            ),
            key: 'edit',
            // icon: <EditOutlined className="normal-20 text-secondary" />,
            disabled: !resourse.write_access,
            onClick: () => {
              return navigate(
                folderId ? `/scripts/${resourse.resource_id}?parentId=${folderId}` : `/scripts/${resourse.resource_id}`,
                {
                  state: resourse
                }
              );
            }
          },
          {
            label: resourse.copy_allowed ? (
              <Typography className={`normal-16  text-secondary fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Duplicate
              </Typography>
            ) : (
              <Tooltip
                title="You are not allowed to duplicate this script. Contact your administrator to help"
                overlayClassName="custom-tooltip"
                placement="bottom"
              >
                <Typography className={`normal-16  text-disabled fnt-mulish`}>Duplicate</Typography>
              </Tooltip>
            ),
            key: 'duplicate',
            disabled: !resourse.write_access,
            onClick: () => handleDuplicate()
          },
          {
            label: (
              <Typography className={`normal-16 text-secondary fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Move to folder
              </Typography>
            ),
            key: 'moveToFolder',
            disabled: !resourse.write_access,
            onClick: () => {
              togglePopup({
                popupName: 'showPopupSelectScript',
                value: true
              });
              setSelectedResourceType('folder');
            }
          },
          ...(folderId
            ? [
                {
                  label: (
                    <Typography className={`normal-16 text-secondary fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                      Remove From Folder
                    </Typography>
                  ),
                  key: 'removeFromFolder',
                  disabled: !resourse.write_access,
                  onClick: () => handleMoveScripts && handleMoveScripts({ folderId: null, scripts: [resourse.resource_id] })
                }
              ]
            : []),

          {
            label: (
              <Typography className={`normal-16 text-red fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Delete script
              </Typography>
            ),
            key: 'delete',
            disabled: !resourse.write_access,
            onClick: () => handleRemove()
          }
        ]
      : [
          {
            label: (
              <Typography className={`normal-16 text-secondary fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Edit Folder
              </Typography>
            ),
            key: 'edit',
            disabled: !resourse.write_access,
            onClick: () => {
              togglePopup({
                popupName: 'showPopupEditFolder',
                value: true
              });
            }
          },
          {
            label: (
              <Typography className={`normal-16 text-secondary fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Sharing Options
              </Typography>
            ),
            key: 'shareToOrganizations',
            disabled: !resourse.write_access,
            onClick: () =>
              togglePopup({
                popupName: 'showPopupSharedFolder',
                value: true
              })
          },
          {
            label: (
              <Typography className={`normal-16 text-secondary fnt-mulishphe ${!resourse.write_access ? 'disabled' : ''}`}>
                Add Existing Scripts
              </Typography>
            ),
            key: 'addExisting',
            disabled: !resourse.write_access,
            onClick: () => {
              togglePopup({
                popupName: 'showPopupSelectScript',
                value: true
              });
              setSelectedResourceType('script');
            }
          },
          {
            label: (
              <Typography className={`normal-16 text-red fnt-mulish ${!resourse.write_access ? 'disabled' : ''}`}>
                Delete folder
              </Typography>
            ),
            key: 'delete',
            disabled: !resourse.write_access,
            // icon: <DeleteOutlined className="normal-20 text-red" />,
            onClick: () => {
              if (resourse.resource_type === 'folder') {
                togglePopup({
                  popupName: 'showPopupDeleteFolder',
                  value: true
                });
              } else {
                handleRemove();
              }
            }
          }
        ];

  const handleRemove = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <Typography className="regular-14">
          Please, confirm deleting <b>{resourse.resource_name}</b> {resourse.resource_type}
        </Typography>
      ),
      okText: 'Delete',
      closable: true,
      okButtonProps: { danger: true },
      onOk: () => {
        if (resourse.resource_id && onRemove) {
          onRemove({
            resourceId: resourse.resource_id,
            resourceType: resourse.resource_type
          });
        }
      }
    });
  }, [resourse, onRemove, popupsState.showPopupDeleteFolder]);

  const handleDuplicate = () => {
    if (!resourse.copy_allowed) return;

    togglePopup({
      popupName: 'showPopupDuplicate',
      value: true
    });
  };

  const handleRemoveFolder = (data: IDeleteResource) => {
    togglePopup({
      popupName: 'showPopupDeleteFolder',
      value: true
    });

    onRemove && onRemove(data);
  };

  return (
    <>
      <PopupsManager
        popupsState={popupsState}
        resourse={resourse}
        resourceType={selectedResourceType}
        togglePopup={togglePopup}
        handleRemove={handleRemoveFolder}
        handleMoveScripts={handleMoveScripts}
        fetchResources={fetchResources}
      />

      <Dropdown className="drop-munu" trigger={['click']} menu={{ items: menuItemsProps?.length ? menuItemsProps : menuItems }}>
        <DropdownIcon className="hint-color cursor-pointer" />
      </Dropdown>
    </>
  );
};
