import { useCallback, useEffect, useState } from 'react';
import { getLiveObjects } from '../api/apiWorker';
import { message } from 'antd';
import { LiveObjects, LiveObjectsIcons, LiveObjectWithId } from '../types';
import { isEmpty, omitBy } from 'lodash';

interface Item {
  name: string;
  file_name: string;
}

interface SubCategory {
  [subCategoryName: string]: Item[];
}

interface Category {
  file_name: string;
  sub_categories: SubCategory;
}

export interface OrganizedLiveObjects {
  [categoryName: string]: Category;
}

interface Props {
  manualFetch?: boolean | undefined;
}

export const useGetLiveObjects = ({ manualFetch = false }: Props = {}) => {
  const [loading, setLoading] = useState(false);
  const [liveObjects, setLiveObjects] = useState<OrganizedLiveObjects>();
  const [basePath, setBasePath] = useState<string>('');
  const [liveObjectWithId, setLiveObjectsWithId] = useState<LiveObjectWithId[]>([]);
  const [liveObjectsIcons, SetLiveObjectsIcons] = useState<LiveObjectsIcons>({});

  const organizeData = (data: LiveObjects) => {
    const { categories, live_objects } = data;
    const organizedData = categories.reduce((acc, category) => {
      acc[category.name] = {
        file_name: category.file_name,
        sub_categories: {}
      };
      return acc;
    }, {} as OrganizedLiveObjects);

    live_objects.forEach(obj => {
      const category = organizedData[obj.category];
      if (category) {
        if (!category.sub_categories[obj.sub_category]) {
          category.sub_categories[obj.sub_category] = [];
        }
        category.sub_categories[obj.sub_category].push({
          name: obj.name,
          file_name: obj.file_name
        });
      }
    });

    return omitBy(organizedData, value => isEmpty(value.sub_categories));
  };

  const fetchLiveObjects = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getLiveObjects();

      if (data?.base_path) {
        setBasePath(data.base_path);
        const organizedData = organizeData(data);
        const loWithId = data.live_objects.map(lo => ({
          name: lo.name,
          object_id: lo.object_id
        }));
        const loIcons = loWithId.reduce((acc, lo) => {
          acc[lo.object_id] = lo.name;
          return acc;
        }, {} as LiveObjectsIcons);
        setLiveObjects(organizedData);
        setLiveObjectsWithId(loWithId);
        SetLiveObjectsIcons(loIcons);
      }
    } catch (e) {
      message.error('Unable to fetch live objects');
      console.error(e, '[fetchLiveObjects]');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!manualFetch) {
      fetchLiveObjects();
    }
  }, [manualFetch, fetchLiveObjects]);

  return { liveObjects, basePath, loading, fetchLiveObjects, liveObjectWithId, liveObjectsIcons };
};
