import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditBtn } from '../../assets/styles/Styled';
import styled from 'styled-components';
import { ChangeVersionFormLabel } from '../../components/Antd/FormLabel';
import { ModalWarningChangeVersion } from './ModalWarningChangeVersion';
import { UpdateVersionsConfigId } from '../../types';

type ChangeVersionFormValues = {
  version?: string;
};

interface IChangeVersionFormProps {
  currentVersion: string;
  loading: boolean;
  updateVersion: (configId: UpdateVersionsConfigId, newVersion: string) => void;
  configId: UpdateVersionsConfigId;
}

const Container = styled.div`
  width: 100%;
  padding: 0 12px;
`;
export const ChangeVersionForm: React.FC<IChangeVersionFormProps> = ({
  configId,
  currentVersion = '',
  loading = false,
  updateVersion
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [form] = Form.useForm<ChangeVersionFormValues>();
  const formVersion = Form.useWatch('version', form);

  useEffect(() => {
    form.setFieldsValue({ version: currentVersion });
  }, [currentVersion, form]);

  useEffect(() => {
    const isInitial = formVersion === currentVersion;
    const isEmpty = !formVersion;
    setIsDisabled(isInitial || isEmpty);
  }, [formVersion, currentVersion]);

  const validateVersion = (_: any, value: string) => {
    if (value === currentVersion) {
      return Promise.reject(new Error('New version must be different from the initial version'));
    }
    return Promise.resolve();
  };

  const validateFormat = (_: any, value: string) => {
    const regex = /^\d+\.\d+\.\d+$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error('Version must be in the format X.X.X'));
    }
    return Promise.resolve();
  };

  const handleSubmit = (values: ChangeVersionFormValues) => {
    setIsVisibleModal(true);
  };

  return (
    <Container>
      <Form
        name="basic"
        className="w-100"
        layout="vertical"
        initialValues={{ version: currentVersion }}
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Form.Item
          label={
            <ChangeVersionFormLabel text={configId === 'client_version' ? 'Update version' : 'Update Goiteens Client Version'} />
          }
          name="version"
          rules={[
            { required: true, message: 'Please Enter new version' },
            { validator: validateVersion },
            { validator: validateFormat }
          ]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input
            type="text"
            placeholder={configId === 'client_version' ? 'Update version' : 'Update Goiteens Client Version'}
            size="large"
          />
        </Form.Item>

        <EditBtn className="btn-orange w-100 mt-4 mr-0" type="submit" disabled={isDisabled || loading}>
          Apply
        </EditBtn>
      </Form>
      {formVersion && (
        <ModalWarningChangeVersion
          modalVisible={isVisibleModal}
          newVersion={formVersion}
          configId={configId}
          setIsVisibleModal={setIsVisibleModal}
          updateVersion={updateVersion}
        />
      )}
    </Container>
  );
};
