import { useEffect, useState } from 'react';
import { Pagination, Resource, ResourceResponse } from '../../../types';
import { extractOrganizationsFromScripts } from '../../../helper';
import { getResources } from '../../../api/apiWorker';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';

interface Props {
  selectedResourceId: string;
  resourceType: 'script' | 'folder';
}

interface ReturnProps {
  isLoading: boolean;
  scriptsTorender: Resource[];
  scriptOrganizationsToRender: string[];
  selectedParentFolderId: string | null;
  pagination: Pagination;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  handleBackToList: () => Promise<void>;
  handleRowDoubleClick: (record: Resource) => void;
  getCurrentResourceIds: (resorceType: 'script' | 'folder') => string[];
  getCurrentScriptsWithParent: (parentId: string) => string[];
}

const paginationInitialState = {
  page: 1,
  pageSize: null,
  totalPages: 1
};

export const useMoveResourse = ({ selectedResourceId, resourceType }: Props): ReturnProps => {
  const [isLoading, setIsLoading] = useState(false);
  const [scriptsTorender, setScriptsToRender] = useState<Resource[]>([]);
  const [scriptOrganizationsToRender, setScriptOrganizationsToRender] = useState<string[]>([]);
  const [selectedParentFolderId, setSelectedParentFolderId] = useState<string | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [pagination, setPagination] = useState<Pagination>(paginationInitialState);
  const [initPage, setInitPage] = useState(false);

  const location = useLocation();

  const calculateAndSetPagination = (resource: ResourceResponse) => {
    const totalPages = Math.ceil(resource.total / resource.page_size);
    setPagination(prev => ({ ...prev, page: resource.page, pageSize: resource.page_size, totalPages: totalPages }));
    setScriptsToRender(resource.data);
  };

  const setData = (resource: ResourceResponse, resourceData: Resource[]) => {
    calculateAndSetPagination(resource);
    setScriptsToRender(resourceData);
    const organizationList = extractOrganizationsFromScripts(resourceData);
    setScriptOrganizationsToRender(organizationList);
  };

  const fetchResource = async (type: 'folder' | 'script' | 'scriptInFolder', queryParams?: string) => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams(location.search);
      const parentId = params.get('folderId');
      const resource = await getResources(
        `?write_allowed=true&page=${pagination.page}${queryParams ? queryParams : ''}${searchValue ? `&search_text=${searchValue}` : ''}`
      );
      if (Array.isArray(resource.data)) {
        const handlers: Record<typeof type, () => void> = {
          folder: () => {
            const resourceData = resource.data.filter(resource => resource.resource_id !== parentId);
            setData(resource, resourceData);
          },
          script: () => {
            const resourceData = resource.data.filter(resource => resource.resource_id !== selectedResourceId);
            setData(resource, resourceData);
          },
          scriptInFolder: () => {
            setData(resource, resource.data);
          }
        };

        if (handlers[type]) {
          handlers[type]();
        }
      }
    } catch (error) {
      message.error('Error fetching scripts');
      console.error(error);
    } finally {
      type !== 'scriptInFolder' && setSelectedParentFolderId(null);
      setIsLoading(false);
    }
  };

  const fetchResourceByType = () => {
    let queryParams = '';
    if (selectedParentFolderId) {
      queryParams = `&parent_id=${selectedParentFolderId}`;
      fetchResource('scriptInFolder', queryParams);
      return;
    }
    if (resourceType === 'folder') {
      queryParams = `&resource_type=${resourceType}`;
      fetchResource('folder', queryParams);
    } else {
      fetchResource('script');
    }
  };

  useEffect(() => {
    fetchResourceByType();
    setInitPage(true);
  }, [resourceType, pagination.page, selectedParentFolderId]);

  useEffect(() => {
    if (initPage) {
      fetchResourceByType();
    }
  }, [searchValue]);

  const handleBackToList = async () => {
    setSelectedParentFolderId(null);
    setPagination(paginationInitialState);
  };

  const handleRowDoubleClick = (record: Resource) => {
    const folderId = record.resource_id;
    setSelectedParentFolderId(folderId);
    setPagination(paginationInitialState);
    return;
  };

  const getCurrentResourceIds = (resorceType: 'script' | 'folder'): string[] => {
    return scriptsTorender.filter(script => script.resource_type === resorceType).map(item => item.resource_id);
  };

  const getCurrentScriptsWithParent = (parentId: string): string[] => {
    return scriptsTorender.filter(script => script.parent_id === parentId).map(item => item.resource_id);
  };

  return {
    isLoading,
    scriptOrganizationsToRender,
    scriptsTorender,
    selectedParentFolderId,
    pagination,
    setPagination,
    setSearchValue,
    handleBackToList,
    handleRowDoubleClick,
    getCurrentResourceIds,
    getCurrentScriptsWithParent
  };
};
