import React, { Suspense } from 'react';
import { Tabs } from '../hooks/useResource';
import { IDeleteResource, IMoveScriptsData, Resource, ResourceDetail } from '../../../types';
import { PopupKeys, PopupsState } from '../hooks/usePopups';

const PopupCreateFolder = React.lazy(() => import('./PopupCreateFolder'));
const PopupDeliteFolder = React.lazy(() => import('./PopupDeliteFolder'));
const PopupSelectScriptOrFolder = React.lazy(() => import('./PopupSelectScriptOrFolder'));
const PopupSharedFolder = React.lazy(() => import('./PopupSharedFolder'));
const PopupErrorAccessSettings = React.lazy(() => import('./PopupErrorAccessSettings'));
const PopupDuplicateScript = React.lazy(() => import('./PopupDuplicateScript'));
const PopupEditFolder = React.lazy(() => import('./PopupEditFolder'));

const PopupsManager = ({
  popupsState,
  // selectedFolder,
  resourse,
  resourceType,
  togglePopup,
  setActiveTab,
  handleRemove,
  handleMoveScripts,
  handleAggreeMoveScripts,
  setRequestDataMoveFiles,
  fetchResources
}: {
  popupsState: PopupsState;
  togglePopup: ({ popupName, value }: { popupName: PopupKeys; value: boolean | null }) => void;
  setActiveTab?: React.Dispatch<React.SetStateAction<Tabs>>;
  handleRemove: (data: IDeleteResource) => void;
  handleMoveScripts?: ({ folderId, scripts }: IMoveScriptsData) => Promise<void>;
  handleAggreeMoveScripts?: () => void;
  setRequestDataMoveFiles?: (data: any) => void;
  fetchResources?: () => Promise<void>;
  resourse?: Resource | ResourceDetail | null;
  resourceType: 'script' | 'folder';
}) => {
  return (
    <Suspense fallback={<></>}>
      {popupsState.showPopupCreateFolder && setActiveTab && (
        <PopupCreateFolder handleTogglePopup={togglePopup} setActiveTab={setActiveTab} />
      )}
      {popupsState.showPopupDeleteFolder && resourse && (
        <PopupDeliteFolder folder={resourse} handleTogglePopup={togglePopup} onRemove={handleRemove} />
      )}
      {popupsState.showPopupSelectScript && (
        <PopupSelectScriptOrFolder
          resourceType={resourceType}
          selectedResourceId={resourse?.resource_id || resourse?.resource_id || ''}
          handleTogglePopup={togglePopup}
          handleMoveScripts={handleMoveScripts}
        />
      )}
      {popupsState.showPopupSharedFolder && resourse && (
        <PopupSharedFolder handleTogglePopup={togglePopup} resourceId={resourse.resource_id} fetchResources={fetchResources} />
      )}
      {popupsState.showPopupErrorAccessSettings && handleAggreeMoveScripts && setRequestDataMoveFiles && (
        <PopupErrorAccessSettings
          handleAggreeMoveScripts={handleAggreeMoveScripts}
          setRequestDataMoveFiles={setRequestDataMoveFiles}
          handleTogglePopup={togglePopup}
        />
      )}
      {popupsState.showPopupDuplicate && resourse && (
        <PopupDuplicateScript
          handleTogglePopup={togglePopup}
          fetchResources={fetchResources}
          title={resourse.resource_name}
          resourceId={resourse.resource_id}
        />
      )}
      {popupsState.showPopupEditFolder && resourse && (
        <PopupEditFolder
          handleTogglePopup={togglePopup}
          resourceId={resourse.resource_id}
          resourceName={resourse.resource_name}
          fetchResources={fetchResources}
        />
      )}
    </Suspense>
  );
};

export default PopupsManager;
