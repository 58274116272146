import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragIcon, PenIcon, PlusIcon } from '../../../../../assets/icons';
import { Dropdown, message, Popconfirm, Typography } from 'antd';

import {
  ActionButtonEnum,
  ActionEnum,
  IActionsConfig,
  IActionsConfigItems,
  IActionsConfigPositions,
  IActionsType,
  IBaseConfigs,
  IPreviewConfig,
  LiveObjectsIcons,
  LoItem,
  MenuItem,
  NewActionsItem,
  ScenarioBlock,
  WhiteboardPreview
} from '../../../../../types';
import { useScriptForm } from '../../../ScriptCreateStore';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNoteActions } from '../../../hooks';
import { DeleteFilled } from '@ant-design/icons';
import { usePreview } from '../../../../../context/PreviewContext';
import { ActionsBlock } from './ActionsBlock';
import {
  BlockActions,
  ButtonAction,
  ButtonAddAction,
  ImageActions,
  NotesBlock,
  NotesContainer,
  PreviewNotesContainer,
  StyledCheckIcon,
  StyledCloseCircleIcon,
  StyledContentPreview,
  StyledIcon,
  StyledRightButtonSection,
  StyledTextArea,
  TextAreaContainer
} from '../../sections.styled';

interface Props {
  block: ScenarioBlock;
  scenarioId: string;
  actionsConfig: IActionsConfig;
  baseActionPaths: IBaseConfigs;
  isShowWhiteboardMenu: boolean;
  isShowOnlyWhiteboardOff: boolean;
  liveObjectsBasePath: string;
  liveObjectsIcons: LiveObjectsIcons;
  setEditableBlockId: React.Dispatch<React.SetStateAction<null | string>>;
  setWhiteboardPreview: React.Dispatch<React.SetStateAction<WhiteboardPreview>>;
  setWhiteboardEditableBlock: React.Dispatch<React.SetStateAction<WhiteboardPreview>>;
  setPreviewConfig: React.Dispatch<React.SetStateAction<IPreviewConfig>>;
}

export const Note: React.FC<Props> = ({
  block,
  scenarioId,
  actionsConfig,
  baseActionPaths,
  isShowWhiteboardMenu,
  isShowOnlyWhiteboardOff,
  liveObjectsBasePath,
  liveObjectsIcons,
  setPreviewConfig,
  setEditableBlockId,
  setWhiteboardPreview,
  setWhiteboardEditableBlock
}) => {
  const {
    formValues: { scenario: scenarioState, live_objects: liveObjects, slides },
    setFormValues,
    setCurrentSection
  } = useScriptForm();
  const { content, actions, id: blockId } = block;
  const actionEntries = Object.entries(actionsConfig);
  const [newContent, setNewContent] = useState(content);
  const [editing, setEditing] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>({});
  const { setActiveBlockId, setLoToPreview, setPaintToPreview } = usePreview();
  const initNewActions = () => {
    if (actions?.length) {
      return actions.map(action => {
        const { action_type, id, group, position_x, position_y, size, uniq_id, live_object_id } = action;

        const result: NewActionsItem = {
          action_type,
          id,
          dropdownKey: `${action_type}-${group}`
        };

        if (position_x !== undefined) result.position_x = position_x;
        if (position_y !== undefined) result.position_y = position_y;
        if (size !== undefined) result.size = size;
        if (uniq_id !== undefined) result.uniq_id = uniq_id;
        if (live_object_id !== undefined) result.live_object_id = live_object_id;

        return result;
      });
    }
    return [];
  };
  const [selectedAction, setSelectedAction] = useState<string[]>([]);
  const [newActions, setNewActions] = useState<NewActionsItem[]>(() => initNewActions());

  const [newActionsToPreview, setNewActionsToPreview] = useState<NewActionsItem[]>([]);
  const [isDraggingAction, setIsDraggingAction] = useState(false);

  useEffect(() => {
    if (!isDraggingAction) {
      const updatedActions = [...newActions];
      const awardsSet = new Set<string>();
      const effectsSet = new Set<string>();
      newActions?.forEach(action => {
        if (action.action_type === 'awards' && action.id) {
          awardsSet.add(action.dropdownKey.split('-')[1]);
        } else if (action.action_type === 'effects' && action.id) {
          effectsSet.add(action.dropdownKey.split('-')[1]);
        }
      });

      newActions?.forEach(action => {
        const group = action.dropdownKey.split('-')[1];
        if (action.action_type === 'awards' && !effectsSet.has(group)) {
          updatedActions.push({
            action_type: 'effects',
            id: '',
            dropdownKey: `effects-${group}`
          });
        } else if (action.action_type === 'effects' && !awardsSet.has(group)) {
          updatedActions.push({
            action_type: 'awards',
            id: '',
            dropdownKey: `awards-${group}`
          });
        }
      });
      updatedActions?.sort((a, b) => {
        const groupA = a.dropdownKey.split('-')[1];
        const groupB = b.dropdownKey.split('-')[1];
        return parseInt(groupA) - parseInt(groupB);
      });

      // Set Updated LO after Delete
      const updatedLoToPreview = updatedActions?.length
        ? updatedActions?.filter(item => item.action_type === 'live_object' || item.id === 'delete_all_live_objects')
        : [];
      updatedLoToPreview?.length
        ? setLoToPreview(prev => {
            if (!prev[blockId]?.length) return prev;
            const updatedItems = updatedLoToPreview
              .map(item => {
                const prevLoToPreview = prev[blockId].find(prevLo => prevLo?.uniq_id === item.uniq_id);
                return {
                  action_type: item.id === 'delete_all_live_objects' ? 'delete_all_live_objects' : item.action_type,
                  dropdownKey: item.dropdownKey,
                  live_object_id:
                    item.id && item.id === 'delete_all_live_objects' ? '' : item.live_object_id ? item.live_object_id : '',
                  uniq_id: item.id && item.id === 'delete_all_live_objects' ? '' : item.uniq_id ? item.uniq_id : '',
                  position_x:
                    prevLoToPreview?.position_x !== undefined && prevLoToPreview?.position_x !== null
                      ? prevLoToPreview?.position_x
                      : 0.35,
                  position_y: prevLoToPreview?.position_y || 0,
                  size: prevLoToPreview?.size || 0.3
                };
              })
              .filter(
                item => item.action_type === 'delete_all_live_objects' || (item.action_type === 'live_object' && item.uniq_id)
              );
            return {
              ...prev,
              [blockId]: updatedItems as LoItem[]
            };
          })
        : setLoToPreview(prev => ({ ...prev, [`${blockId}`]: [] }));

      // Set updated Whiteboard
      const actualWhiteboard = updatedActions.length
        ? updatedActions
            ?.filter(item => item.action_type === 'whiteboard')
            .map(item => ({ action: item.id, dropdownKey: item.dropdownKey }))
        : [];
      const updatedWhiteboard = actualWhiteboard.map((item, idx) => ({
        action: item.action,
        slideIdx: idx,
        blockId: blockId,
        dropdownKey: `${item.dropdownKey}`
      }));
      setWhiteboardPreview(prev => {
        return {
          ...prev,
          [blockId]: updatedWhiteboard
        };
      });

      if (editing) {
        setWhiteboardEditableBlock({ [blockId]: updatedWhiteboard });
      }

      //setDrawing
      const actualDrawingActions = updatedActions.filter(item => item.action_type === 'drawing');
      actualDrawingActions.length
        ? setPaintToPreview(prev => ({
            ...prev,
            [blockId]: actualDrawingActions.map(item => ({
              dropdownKey: item.dropdownKey,
              action_type: item.id
            }))
          }))
        : setPaintToPreview(prev => ({
            ...prev,
            [blockId]: []
          }));

      if (!editing) {
        setSelectedAction(updatedActions.map(action => action.action_type).filter(item => item !== 'effects'));
        setNewActionsToPreview(updatedActions);
      }
    }
  }, [newActions, editing]);

  const menuItems: MenuItem[] = actionEntries
    .map(([key, value]) => ({
      key: key,
      label: ActionEnum[key as keyof typeof ActionEnum]
    }))
    .filter(item => item.label && item.key !== 'effects');

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: blockId });
  const [selectedActionsItem, setSelectedActionsItem] = useState<string[]>([]);
  const { menuActions, actionIcons, renderMenuActionsItems, renderLoMenuActionsItems } = useNoteActions({
    menuItems,
    actionsConfig,
    baseActionPaths,
    isShowWhiteboardMenu,
    isShowOnlyWhiteboardOff,
    blockId,
    liveObjects,
    liveObjectsBasePath,
    newActions,
    slides,
    setSelectedAction,
    setNewActions,
    setWhiteboardEditableBlock,
    setWhiteboardPreview,
    setCurrentSection
  });

  useEffect(() => {
    setActiveBlockId(blockId);
  }, [blockId, scenarioState]);

  useEffect(() => {
    let allSelectedItems = document.querySelectorAll('.selected');
    allSelectedItems.forEach(item => item.classList.remove('selected'));
    const validClassNames = selectedActionsItem.filter(className => className.trim() !== '');
    if (validClassNames.length === 0) return;
    const selectedItems = validClassNames.flatMap(className =>
      Array.from(document.querySelectorAll('*')).filter(element => element.classList.contains(`${className}`))
    );
    if (selectedActionsItem.length) {
      if (Object.values(openDropdowns).filter(item => item).length) {
        selectedItems.forEach(item => item.classList.add('selected'));
      } else {
        setSelectedActionsItem([]);
      }
    }
  }, [openDropdowns, selectedActionsItem]);
  const handleSaveNote = useCallback(() => {
    const isEmptyActions = !newActions.length || !newActions.filter(item => item.id).length;
    if (isEmptyActions && !newContent) {
      return message.error('Notes cannot be empty');
    }
    const actionsToSave = newActions.filter(item => item.id);
    const dataToSave = actionsToSave
      .sort((a, b) => {
        const aIndex = a.dropdownKey ? parseInt(a.dropdownKey.split('-').pop()!, 10) : 0;
        const bIndex = b.dropdownKey ? parseInt(b.dropdownKey.split('-').pop()!, 10) : 0;

        return aIndex - bIndex;
      })
      .filter(item => !(item.action_type === 'live_object' && item.id === 'place_live_objects' && !item.uniq_id))
      .map(item => {
        return {
          ...item
        };
      });
    if (!dataToSave.length && !newContent) {
      return message.error('Notes cannot be empty');
    }
    const updatedScenarioItems = scenarioState.map((scenario, index) => {
      if (scenario.id === scenarioId) {
        const updatedScenario = { ...scenario };
        updatedScenario.blocks = updatedScenario.blocks.map(block => {
          const actionsToUpdate = dataToSave.map(action => {
            const groupeId = action.dropdownKey.split('-')[1];
            if (action.action_type === 'live_object' && action.id === 'place_live_objects') {
              return {
                action_type: action.action_type,
                id: action.id,
                group: groupeId,
                position_x: action.position_x,
                position_y: action.position_y,
                size: action.size,
                uniq_id: action.uniq_id,
                live_object_id: action.live_object_id
              };
            }
            return {
              action_type: action.action_type,
              id: action.id,
              group: groupeId
            };
          });
          return block.id === blockId ? { ...block, content: newContent, actions: actionsToUpdate || [] } : block;
        });
        return updatedScenario;
      } else {
        return scenario;
      }
    });
    setFormValues(prevState => ({ ...prevState, scenario: updatedScenarioItems }));
    setEditing(false);
    setEditableBlockId(null);
    setActiveBlockId(null);
    setNewActions(actionsToSave);
  }, [scenarioState, scenarioId, blockId, setFormValues, newContent, newActions]);
  const handleRemove = () => {
    const updatedScenarioItems = scenarioState.map(scenario => {
      if (scenario.id === scenarioId) {
        const updatedBlocks = scenario.blocks.filter(block => block.id !== blockId);
        return { ...scenario, blocks: updatedBlocks };
      } else {
        return scenario;
      }
    });

    setFormValues(prevState => ({ ...prevState, scenario: updatedScenarioItems }));
    setActiveBlockId(null);
  };

  const handleRemoveAction = (selectedActionIdx: number) => {
    if (!selectedAction && !selectedActionIdx) return;
    const actionType = selectedAction[selectedActionIdx];
    const updatedScenarioActionsItems = scenarioState.map(scenario => {
      if (scenario.id === scenarioId) {
        return {
          ...scenario,
          blocks: scenario.blocks.map(block => {
            if (block.id === blockId) {
              const updatedActions = block.actions?.filter(
                action => action?.action_type === selectedAction[selectedActionIdx] && action?.group !== `${selectedActionIdx}`
              );
              return { ...block, actions: updatedActions };
            }
            return block;
          })
        };
      }
      return scenario;
    });
    const filteredActionsWithReverse = newActions
      .filter((item, index) => {
        const key = item.dropdownKey.split('-').reverse()[0];
        return +key !== selectedActionIdx;
      })
      .reverse();
    const filteredActions = newActions.filter((item, index) => {
      const key = item.dropdownKey.split('-').reverse()[0];
      if (actionType !== 'awards') {
        return +key !== selectedActionIdx;
      }
      return item.dropdownKey !== `awards-${selectedActionIdx}` && item.dropdownKey !== `effects-${selectedActionIdx}`;
    });

    if (selectedAction[selectedActionIdx] === 'backgrounds') {
      const currentBackground = filteredActionsWithReverse.find(item => item.action_type === 'backgrounds');
      if (currentBackground?.id) {
        setPreviewConfig(prev => ({
          ...prev,
          backgrounds: currentBackground.id
        }));
      } else {
        setPreviewConfig(prev => {
          const { backgrounds, ...rest } = prev;
          return rest;
        });
      }
    }

    if (selectedAction[selectedActionIdx] === 'character_moving_actions') {
      const currentPosition = filteredActionsWithReverse.find(item => item.action_type === 'character_moving_actions');
      if (currentPosition?.id) {
        setPreviewConfig(prev => ({
          ...prev,
          character_moving_actions: currentPosition.id
        }));
      } else {
        setPreviewConfig(prev => {
          const { character_moving_actions, ...rest } = prev;
          return rest;
        });
      }
      setFormValues(prevState => ({ ...prevState, scenario: updatedScenarioActionsItems }));
    }

    if (editing) {
      setSelectedAction(prev => prev.filter((item, idx) => idx !== selectedActionIdx));
    }

    const groupedActionsByType: { [key: string]: any[] } = {};
    filteredActions.forEach(action => {
      const [, index] = action.dropdownKey.split('-');
      if (!groupedActionsByType[index]) {
        groupedActionsByType[index] = [];
      }
      groupedActionsByType[index].push(action);
    });
    const groupedActions = Object.values(groupedActionsByType);
    const actionsByIndex = groupedActions.flatMap((group, groupIndex) =>
      group.map(item => ({
        ...item,
        dropdownKey: `${item.action_type}-${groupIndex}`
      }))
    );

    setNewActions(actionsByIndex);
  };
  const renderIcon = useCallback(
    (actionType: IActionsType, dropdownKey: string) => {
      let dropdownKeyToRender = dropdownKey;
      if (actionType === 'effects') {
        dropdownKeyToRender = dropdownKey.replace('awards', 'effects');
      }

      const selectedActionFromConfig = actionsConfig[actionType as keyof typeof actionsConfig];
      const selectedActionId = newActions.find(item => item.dropdownKey === dropdownKeyToRender)?.id;
      if ((actionType === 'drawing' || actionType === 'whiteboard' || actionType === 'live_object') && selectedActionId) {
        return actionIcons[selectedActionId];
      }
      const filteredConfig = selectedActionFromConfig as (IActionsConfigItems | IActionsConfigPositions)[];

      const icon = selectedActionId && filteredConfig.find(item => item.id === selectedActionId)?.icon;

      if (icon) {
        return (
          <ImageActions
            style={{ width: '25px', height: '25px', marginRight: '4px' }}
            key={icon}
            src={baseActionPaths.actions_media_base_path + icon + '.png'}
            alt={icon}
          />
        );
      }
      return actionIcons[actionType];
    },

    [newActions, actionIcons, actionsConfig, selectedAction]
  );

  const renderIconItemsLo = useCallback(
    (actionType: IActionsType, dropdownKey: string) => {
      const currentIcon = newActions?.find(item => item.dropdownKey === dropdownKey)?.live_object_id;
      const currentLoId = newActions?.find(item => item.dropdownKey === dropdownKey)?.id;
      const iconLoToPreview = liveObjectsIcons[currentIcon as keyof typeof liveObjectsIcons];
      if (iconLoToPreview) {
        return (
          <ImageActions
            style={{ width: '20px', height: '20px', marginRight: '4px', objectFit: 'contain' }}
            key={`${iconLoToPreview} + ${dropdownKey}`}
            src={liveObjectsBasePath + iconLoToPreview + '.png'}
            alt={iconLoToPreview}
          />
        );
      }
      if (currentLoId) {
        return actionIcons[currentLoId];
      }
      if (!currentIcon) {
        return actionIcons[actionType];
      }
    },
    [newActions, actionIcons, liveObjectsBasePath, blockId, liveObjectsIcons]
  );
  function trimAfterFirstUppercase(str: string) {
    const index = str.slice(1).search(/[A-Z]/) + 1;
    if (index > 0) {
      return str.slice(0, index);
    }
    return str;
  }

  function trimPositionUppercase(str: string) {
    const index = str.slice(1).search(/[A-Z]/) + 1;
    if (index > 0) {
      return str.slice(index);
    }
    return str;
  }

  const renderTitle = useCallback(
    (actionType: string, dropdownKey: string) => {
      if (actionType === 'effects' || actionType === 'awards') return;

      const selectedActionId = newActions?.find(item => item.dropdownKey === dropdownKey)?.id;

      if (
        (actionType === 'drawing' ||
          actionType === 'whiteboard' ||
          actionType === 'live_object' ||
          actionType === 'place_live_objects') &&
        selectedActionId
      ) {
        return ActionButtonEnum[selectedActionId as keyof typeof ActionButtonEnum];
      }
      if (actionType === 'character_moving_actions' && selectedActionId) {
        const position = trimPositionUppercase(selectedActionId);
        return <span style={{ padding: '0 5px' }}>{position}</span>;
      }
      if (selectedActionId) {
        return <span style={{ padding: '0 5px' }}>{selectedActionId}</span>;
      }

      return <span style={{ padding: '0 5px' }}>{ActionButtonEnum[actionType as keyof typeof ActionButtonEnum]}</span>;
    },

    [newActions, selectedAction]
  );

  const renderIconAwardsEffects = useCallback(
    (dropdownKey: string) => {
      let dropdownKeyEffects = dropdownKey;
      dropdownKeyEffects = dropdownKey.replace('awards', 'effects');

      const selectedActionIdAward = newActions?.find(item => item.dropdownKey === dropdownKey)?.id;
      const selectedActionIdEffect = newActions?.find(item => item.dropdownKey === dropdownKeyEffects)?.id;

      const iconAward = selectedActionIdAward && actionsConfig['awards'].find(item => item.id === selectedActionIdAward)?.icon;
      const iconEffect =
        selectedActionIdEffect && actionsConfig['effects'].find(item => item.id === selectedActionIdEffect)?.icon;

      if (iconAward && !iconEffect) {
        return (
          <ImageActions
            style={{ width: '25px', height: '25px', marginRight: '4px' }}
            key={iconAward}
            src={baseActionPaths.actions_media_base_path + iconAward + '.png'}
            alt={iconAward}
          />
        );
      } else if (iconEffect && !iconAward) {
        return (
          <ImageActions
            style={{ width: '25px', height: '25px', marginRight: '4px' }}
            key={iconEffect}
            src={baseActionPaths.actions_media_base_path + iconEffect + '.png'}
            alt={iconEffect}
          />
        );
      } else if (iconEffect && iconAward) {
        return (
          <>
            <ImageActions
              style={{ width: '25px', height: '25px', marginRight: '4px' }}
              key={iconAward}
              src={baseActionPaths.actions_media_base_path + iconAward + '.png'}
              alt={iconAward}
            />
            <ImageActions
              style={{ width: '25px', height: '25px', marginRight: '4px' }}
              key={iconEffect}
              src={baseActionPaths.actions_media_base_path + iconEffect + '.png'}
              alt={iconEffect}
            />
          </>
        );
      }

      return actionIcons['awards'];
    },

    [newActions, actionIcons, actionsConfig]
  );

  const renderTitleAwardsEffects = useCallback(
    (dropdownKey: string) => {
      let dropdownKeyEffects = dropdownKey;
      dropdownKeyEffects = dropdownKey.replace('awards', 'effects');

      const selectedActionIdAward = newActions?.find(item => item.dropdownKey === dropdownKey)?.id;
      const selectedActionIdEffect = newActions?.find(item => item.dropdownKey === dropdownKeyEffects)?.id;

      if (selectedActionIdAward && !selectedActionIdEffect) {
        const title = trimAfterFirstUppercase(selectedActionIdAward);
        return <span style={{ padding: '0 5px' }}>{title}</span>;
      } else if (!selectedActionIdAward && selectedActionIdEffect) {
        const title = trimAfterFirstUppercase(selectedActionIdEffect);
        return <span style={{ padding: '0 5px' }}>{title}</span>;
      } else if (selectedActionIdAward && selectedActionIdEffect) {
        const title = trimAfterFirstUppercase(selectedActionIdAward);
        const titleEffect = trimAfterFirstUppercase(selectedActionIdEffect);
        return (
          <>
            <span style={{ padding: '0 5px' }}>{title}</span>
            <span style={{ padding: '0 5px' }}>{titleEffect}</span>
          </>
        );
      }
      return (
        <>
          <span style={{ padding: '0 5px' }}>{ActionButtonEnum['awards']}</span>
          {/* <span style={{ padding: '0 5px' }}>{ActionButtonEnum['effects']}</span> */}
        </>
      );
    },

    [newActions]
  );
  const renderTitleLo = useCallback(
    (dropdownKey: string) => {
      const currentLoId = newActions?.find(item => item.dropdownKey === dropdownKey)?.live_object_id;
      const currentLoAction = newActions?.find(item => item.dropdownKey === dropdownKey)?.id;
      const currentLoName = liveObjectsIcons[currentLoId as keyof typeof liveObjectsIcons];
      if (currentLoName) {
        return currentLoName.replace('Icon', '');
      }
      if (currentLoAction === 'delete_all_live_objects') {
        return ActionButtonEnum[currentLoAction as keyof typeof ActionButtonEnum];
      }
      if (!currentLoId) {
        return 'Choose LO';
      }
    },

    [newActions, blockId, liveObjectsIcons]
  );

  const handleOpenDropdown = (dropdownKey: string, flag: boolean) => {
    setOpenDropdowns(prevState => ({
      ...prevState,
      [dropdownKey]: flag
    }));
  };

  const draggableStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <>
      {(!actions?.length && !content) || editing ? (
        <NotesContainer>
          <TextAreaContainer>
            <StyledTextArea rows={2} autoFocus value={newContent} onChange={e => setNewContent(e.target.value)} />
          </TextAreaContainer>
          {!!selectedAction.length && (
            <ActionsBlock
              blockId={blockId}
              liveObjects={liveObjects}
              newActions={newActions}
              openDropdowns={openDropdowns}
              selectedAction={selectedAction}
              handleOpenDropdown={handleOpenDropdown}
              handleRemoveAction={handleRemoveAction}
              renderIconAwardsEffects={renderIconAwardsEffects}
              renderIconItemsLo={renderIconItemsLo}
              renderLoMenuActionsItems={renderLoMenuActionsItems}
              renderMenuActionsItems={renderMenuActionsItems}
              renderTitleAwardsEffects={renderTitleAwardsEffects}
              renderTitleLo={renderTitleLo}
              renderTitle={renderTitle}
              renderIcon={renderIcon}
              setSelectedActionsItem={setSelectedActionsItem}
              setNewActions={setNewActions}
              setSelectedAction={setSelectedAction}
              setIsDraggingAction={setIsDraggingAction}
              setPreviewConfig={setPreviewConfig}
            />
          )}

          {!!menuItems.length && selectedAction.length <= 4 && (
            <Dropdown
              menu={menuActions}
              placement="top"
              overlayClassName="overly-menu-note"
              className="drop-menu"
              trigger={['click']}
              disabled={!menuItems.length}
            >
              <ButtonAddAction>
                <StyledIcon />
                Add action
                <StyledRightButtonSection>
                  <PlusIcon />
                </StyledRightButtonSection>
              </ButtonAddAction>
            </Dropdown>
          )}

          <StyledCloseCircleIcon
            onClick={() => {
              if (editing) {
                setNewActions(() => initNewActions());
                setNewContent(content);
                setEditing(false);
                setEditableBlockId(null);
                if (!newActions.length) {
                  handleRemove();
                }
              } else {
                handleRemove();
              }
              setActiveBlockId(null);
            }}
          />
          <StyledCheckIcon onClick={handleSaveNote} />
        </NotesContainer>
      ) : (
        <PreviewNotesContainer ref={setNodeRef} style={{ ...draggableStyle, minHeight: 'unset' }} {...attributes}>
          <DragIcon {...listeners} className=" drag-icon-absolute cursor-grab" />
          <PenIcon
            className="cursor-pointer pen-icon-absolute"
            onClick={() => {
              setEditableBlockId(blockId);
              setActiveBlockId(blockId);
              setEditing(true);
            }}
          />

          <Popconfirm title="Delete this Action?" okText="Delete" okButtonProps={{ danger: true }} onConfirm={handleRemove}>
            <DeleteFilled className="cursor-pointer delete-icon-absolute" />
          </Popconfirm>
          <BlockActions>
            {content && (
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                <StyledContentPreview className="normal-16 m-0 primary-color">{content}</StyledContentPreview>
              </pre>
            )}

            {newActionsToPreview.map((item, idx) => {
              if (item.action_type !== 'effects') {
                return (
                  <NotesBlock key={item.id + idx}>
                    {ActionEnum[item.action_type as keyof typeof ActionEnum]}
                    <ButtonAction disabled={true}>
                      {item.action_type !== 'live_object' && item.action_type !== 'awards'
                        ? renderIcon(item.action_type as IActionsType, item.dropdownKey)
                        : item.action_type === 'live_object'
                          ? renderIconItemsLo(item.action_type as IActionsType, item.dropdownKey)
                          : item.action_type === 'awards' && renderIconAwardsEffects(item.dropdownKey)}

                      {item.action_type !== 'live_object' && item.action_type !== 'awards'
                        ? renderTitle(item.action_type as IActionsType, item.dropdownKey)
                        : item.action_type === 'live_object'
                          ? renderTitleLo(item.dropdownKey)
                          : item.action_type === 'awards' && renderTitleAwardsEffects(item.dropdownKey)}
                    </ButtonAction>
                  </NotesBlock>
                );
              }
            })}
          </BlockActions>
        </PreviewNotesContainer>
      )}
    </>
  );
};
