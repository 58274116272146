import styled from 'styled-components';

export const FilterPanelWrapper = styled.div`
  display: flex;
  gap: 0 12px;
  align-items: center;
  padding: 21px 0;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 12px;
  }

  .folder-icon {
    > svg {
      width: 18px;
      height: 18px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .filter-panel {
    margin-top: 0;
    margin-bottom: 0;
    width: max-content;

    @media (max-width: 450px) {
      margin-right: auto;
    }

    .btn-filter {
      .btn-filter-text {
        font-size: 14px !important;
        width: max-content;
      }
    }
  }
`;

export const BreadCrumbs = styled.div<{ isActiveSearch: boolean }>`
  font-size: 24px;
  font-weight: 800;
  color: var(--primary-text);
  width: ${({ isActiveSearch }) => (isActiveSearch ? '100px' : '100%')};
  max-width: ${({ isActiveSearch }) => (isActiveSearch ? '100px' : '400px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    max-width: ${({ isActiveSearch }) => (isActiveSearch ? '100px' : '303px')};
  }
  @media (max-width: 450px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  background: var(--text-disabled);
  border-radius: 17px;
  margin-bottom: 18px;
  margin-top: -24px;
`;

export const Tab = styled.div`
  font-family: 'Mulish', sans-serif !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 8px;
  gap: 8px;
  color: var(--light-description);
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  span {
    svg {
      path {
        fill: var(--light-description);
      }
    }
  }

  &.active {
    font-weight: 800;

    background: #fff;
    box-shadow: 0px 3.45px 13.79px 0px rgba(0, 0, 0, 0.07);

    border-radius: 13px;
    color: var(--description-solid);
    span {
      svg {
        path {
          fill: var(--description-solid);
        }
      }
    }
  }
`;

export const FilterPanelItems = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  gap: 12px;

  .search-hide {
    width: 34px;
    box-shadow: none !important;
    transition: width 0.3s;

    input {
      width: 0;
      transition: width 1s;
    }
    .ant-input-suffix {
      padding: 0 5px;
    }
  }
  .search-visible {
    width: 100%;
    transition: width 0.6s;
    // &:active,
    // &:focus-within {
    box-shadow: none !important;
    outline: 1px solid #d9d9d9 !important;

    .ant-input-suffix {
      border-left: 1px solid #d9d9d9 !important;
      padding: 0 5px;
    }
    // }
  }
`;
