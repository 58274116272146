import React, { createContext, useEffect } from 'react';
import { Modal } from 'antd';
import { UpdateVersionsConfigId } from '../../types';

const ReachableContext = createContext<string | null>(null);

interface IModalWarningChangeVersion {
  modalVisible: boolean;
  newVersion: string;
  configId: UpdateVersionsConfigId;
  setIsVisibleModal: (value: boolean) => void;
  updateVersion: (configId: UpdateVersionsConfigId, newVersion: string) => void;
}

export const ModalWarningChangeVersion: React.FC<IModalWarningChangeVersion> = ({
  modalVisible,
  newVersion,
  configId,
  setIsVisibleModal,
  updateVersion
}) => {
  const config = {
    title: 'Are you sure? (forced update)',
    content: `This will set minimum required ${configId === 'client_version' ? 'client version' : 'goiteens client version'} to ${newVersion}.`,
    okText: 'Continue',
    cancelText: 'Cancel',
    onOk: () => {
      updateVersion(configId, newVersion);
      setIsVisibleModal(false);
    },
    onCancel: () => {
      setIsVisibleModal(false);
    }
  };
  const [modal, contextHolder] = Modal.useModal();

  const openModal = async () => {
    await modal.confirm(config);
  };

  useEffect(() => {
    modalVisible && openModal();
  }, [modalVisible]);

  return <ReachableContext.Provider value="Light">{contextHolder}</ReachableContext.Provider>;
};
