import { useState } from 'react';

export interface PopupsState {
  showPopupCreateFolder: boolean | null;
  showPopupSelectScript: boolean | null;
  showPopupSharedFolder: boolean | null;
  showPopupDeleteFolder: boolean | null;
  showPopupErrorAccessSettings: boolean | null;
  showPopupDuplicate: boolean | null;
  showPopupEditFolder: boolean | null;
}

export type PopupKeys = keyof PopupsState;

export const usePopups = () => {
  const [popupsState, setPopupsState] = useState<PopupsState>({
    showPopupCreateFolder: null,
    showPopupSelectScript: null,
    showPopupSharedFolder: null,
    showPopupDeleteFolder: null,
    showPopupErrorAccessSettings: null,
    showPopupDuplicate: null,
    showPopupEditFolder: null
  });

  const togglePopup = ({ popupName, value }: { popupName: PopupKeys; value: boolean | null }) => {
    setPopupsState(prev => ({ ...prev, [popupName]: value }));
  };

  return { popupsState, togglePopup };
};
