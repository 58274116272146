import React, { useEffect, useState } from 'react';
import { CreateAccountBtnWrapper, PageContainer, PageContent, PageWrapper, StyledButton } from '../../assets/styles/Styled';
import { Link } from 'react-router-dom';
import { message, Space, Spin, Tooltip, Typography } from 'antd';
import { Search } from '../../components/Search';
import { deleteOrganization } from '../../api/apiWorker';
import { OrganizationItem } from '../../types';
import styled from 'styled-components';
import { OrganizationListItem } from './OrganizationListItem';
import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import { headerHeight } from '../../assets/variables/variables';

const OrganizationsListContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  overflow: auto;
  padding-bottom: 20px;
  max-height: calc(100% - ${headerHeight.desctop}px);

  @media (max-width: 1120px) {
    max-height: calc(100% - ${headerHeight.tablet}px);
  }
  @media (max-width: 800px) {
    max-height: calc(100% - ${headerHeight.mobile}px);
  }
`;

const OrganizationsPage = () => {
  const { organizations, fetchOrganizations, organizationsLoading } = useAppContext();
  const { user } = useAuth();
  const isAdmin = user?.user.role === 'admin';
  const [filteredOrganizations, setFilteredOrganizations] = useState<OrganizationItem[]>([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRemove = async (organizationId: string) => {
    setLoading(true);
    try {
      await deleteOrganization(organizationId);
      message.success('Organization was deleted successfully');
      await fetchOrganizations();
    } catch (e) {
      message.error('Unable to delete organizations');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = organizations.filter(organization =>
      organization.organization_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOrganizations(filtered);
  }, [searchText, organizations]);

  return (
    <PageWrapper>
      <PageContainer>
        <PageContent>
          <Space direction="vertical" size={24}>
            <Tooltip
              color="#ffffff"
              title={
                !isAdmin && (
                  <Typography className="extrabold-14 hint-color text-align-center">
                    Only administrators can create new organizations
                  </Typography>
                )
              }
            >
              <CreateAccountBtnWrapper disabled={!isAdmin}>
                <Link to="/organizations/new">
                  <StyledButton disabled={!isAdmin}>Create Organization</StyledButton>
                </Link>
              </CreateAccountBtnWrapper>
            </Tooltip>
            <Search placeholder="Existing Organizations" onChange={search => setSearchText(search)} />
          </Space>
          <OrganizationsListContainer>
            {loading || organizationsLoading ? (
              <Spin spinning={true} tip="Loading...">
                <></>
              </Spin>
            ) : (
              <Space size={12} direction="vertical" className="w-100">
                {filteredOrganizations.map(organization => (
                  <OrganizationListItem key={organization.organization_id} organization={organization} onRemove={handleRemove} />
                ))}
              </Space>
            )}
          </OrganizationsListContainer>
        </PageContent>
      </PageContainer>
    </PageWrapper>
  );
};

export default OrganizationsPage;
