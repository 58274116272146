import React from 'react';
import { StyledTable } from '../../components/Antd/StyledTable';
import { IDeleteResource, IMoveScriptsData, OrganizationItem, Resource } from '../../types';
import { TableProps } from 'antd';
import { DropDown } from './DropDown';
import { OrganizationTags } from '../../components/OrganizationTags';
import { FileIcon, FolderIcon } from '../../assets/icons';
import { IRowSelection } from './popups/PopupSelectScriptOrFolder';
import useMedia from '../../hooks/useMedia';

interface Props {
  tableData: Resource[];
  loading: boolean;
  rowSelection?: IRowSelection;
  tableColumnsProps?: TableProps<Resource>['columns'];
  isHideBody?: boolean;
  withPagination?: boolean;
  handleRowDoubleClick?: (record: Resource) => void;
  fetchResources?: () => Promise<void>;
  handleMoveScripts?: ({ folderId, scripts }: IMoveScriptsData) => Promise<void>;
  onRemove?: (data: IDeleteResource) => void;
}

export const ScriptsTable: React.FunctionComponent<Props> = ({
  tableData,
  loading,
  tableColumnsProps,
  rowSelection,
  isHideBody,
  withPagination = false,
  onRemove,
  fetchResources,
  handleRowDoubleClick,
  handleMoveScripts
}) => {
  const { isMobile, isTablet, isDesktop } = useMedia({ mobileMaxWidth: 450, tabletMaxWidth: 1120 });

  const tableScrollHeight = isDesktop ? '400' : isTablet ? '480' : '540';
  const paginationHeight = isDesktop ? '80' : isTablet ? '80' : '60';

  const tableColumns: TableProps<Resource>['columns'] = [
    {
      title: 'Type',
      dataIndex: 'resource_type',
      key: 'resource_type',
      className: 'extrabold-14',
      width: '8%',
      render: (resource_type: Resource['resource_type']) =>
        resource_type === 'script' ? (
          <FileIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} className="hint-color" />
        ) : (
          <FolderIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} />
        )
    },
    {
      title: 'Name',
      dataIndex: 'resource_name',
      key: 'resource_name',
      className: 'extrabold-14',
      width: '25%',
      ellipsis: true
    },
    {
      title: 'Creator',
      dataIndex: 'owner',
      key: 'owner',
      className: 'extrabold-14',
      width: '25%',
      ellipsis: true
      // render: () => <span>someCreator@gmail.com</span>
    },
    {
      title: 'Organization',
      dataIndex: 'organization_names',
      key: 'organization_names',
      width: '32%',
      render: (organizations: string[]) => <OrganizationTags organizations={organizations.map(item => item)} />
    },
    {
      title: ' ',
      key: 'menu',
      width: '8%',
      align: 'center',
      render: (value, resourse) => (
        <DropDown resourse={resourse} onRemove={onRemove} fetchResources={fetchResources} handleMoveScripts={handleMoveScripts} />
      )
    }
  ];
  return (
    <StyledTable
      rowSelection={rowSelection}
      columns={tableColumnsProps || tableColumns}
      dataSource={tableData}
      rowKey="resource_id"
      loading={loading}
      pagination={false}
      scroll={{
        y: isHideBody ? 0 : `calc(100vh - ${tableScrollHeight}px ${withPagination ? ' - ' + paginationHeight + 'px' : ''})`,
        x: 300
      }}
      showSorterTooltip={false}
      onRow={record => {
        return {
          [isMobile ? 'onClick' : 'onDoubleClick']: (
            event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
          ) => {
            event.stopPropagation();
            handleRowDoubleClick && record.resource_type === 'folder' && handleRowDoubleClick(record);
          }
        };
      }}
    />
  );
};
