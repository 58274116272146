import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Logo, RegistrationWrapper } from '../../assets/styles/Styled';
import { BackLink } from '../../components/BackLink';
import styled from 'styled-components';
import { Typography } from 'antd';
import { User } from '../../types';
import { RegistrationForm } from './RegistrationForm';
import { Information } from './Information';

const Container = styled.div`
  padding-top: 32px;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
`;

const HeaderText = styled(Typography)`
  margin: 16px 0 32px 0;
`;

type StateValues = User & {
  passwordGenerated?: boolean;
};

const UserRegistration: React.FC = () => {
  const { state } = useLocation();
  const [stateValues, setStateValues] = useState<StateValues | null>(null);
  useEffect(() => {
    if (state?.user_id) {
      setStateValues({ ...state, passwordGenerated: true });
    }
  }, [state]);
  return (
    <Container>
      {!stateValues && <BackLink title="New User Registration" path="/admin" />}
      <RegistrationWrapper>
        <Logo />
        {!stateValues && <HeaderText className="bold-24 text-basic">New user registration</HeaderText>}
        {stateValues && !stateValues.passwordGenerated && (
          <Typography className="bold-24 dark-green text-align-center">New account successfully created</Typography>
        )}
        {stateValues?.passwordGenerated && (
          <Typography className="bold-24 dark-green text-align-center">Password successfully regenerated</Typography>
        )}
        {stateValues && <Typography className="bold-16 text-basic">Information for authorization</Typography>}
        {!stateValues ? <RegistrationForm onFinish={values => setStateValues(values)} /> : <Information {...stateValues} />}
      </RegistrationWrapper>
    </Container>
  );
};

export default UserRegistration;
