import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

const StyledSearch = styled(Input)`
  border: none;
  outline: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: 40px !important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
  }

  &:active,
  &:focus-within {
    box-shadow: 0 1px var(--hint-color) !important;
  }
`;

interface Props {
  placeholder: string;
  onChange: (text: string) => void;
  setToggleSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  value?: string;
}

export const Search: React.FunctionComponent<Props> = ({ onChange, placeholder, setToggleSearch, className, value }) => {
  const [clicked, setClicked] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedOnChange = useCallback(debounce(onChange, 500), []);
  const location = useLocation();
  useEffect(() => {
    // setSearch('');
    // onChange('');
    setToggleSearch && (search ? setToggleSearch(true) : setToggleSearch(false));
  }, [location.search]);

  const hidePlaceholder = useMemo(() => {
    return clicked || !!search;
  }, [clicked, search]);

  return (
    <StyledSearch
      className={className || ''}
      value={value || search}
      onClick={() => setClicked(true)}
      onBlur={() => {
        setClicked(false);
        setToggleSearch && !search.length && setToggleSearch(false);
      }}
      placeholder={hidePlaceholder ? 'Search...' : ''}
      size="large"
      prefix={
        <Typography
          onClick={() => setClicked(true)}
          style={{ display: hidePlaceholder ? 'none' : 'block' }}
          className="extrabold-24"
        >
          {placeholder}
        </Typography>
      }
      suffix={
        <SearchOutlined
          onClick={() => {
            setClicked(true);
            setToggleSearch && setToggleSearch(true);
          }}
          className="folder-icon light-description"
        />
      }
      onChange={e => {
        setSearch(e.target.value);
        debouncedOnChange(e.target.value);
      }}
    />
  );
};
