import { Checkbox, Input } from 'antd';
import styled from 'styled-components';
import { Button, EditBtn, PopUpContentDelete, PopUpWrapperDelete } from '../../assets/styles/Styled';

export const PopUpWrapper = styled(PopUpWrapperDelete)`
  z-index: 10;
  & .close {
    top: 27px;
  }
`;

export const StyledPopUpContent = styled(PopUpContentDelete)`
  max-width: 730px;
  width: 100%;
  gap: 14px;

  & > form {
    width: 100%;
  }
`;

export const StyledPopupHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ButtonSubmit = styled(EditBtn)`
  margin: 14px auto 0;
  background: linear-gradient(180deg, #ffd08a 0%, #ed6400 100%);
  min-height: 48px;
`;

export const StyledInput = styled(Input)`
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 3.66px 14.65px 0px rgba(0, 0, 0, 0.07);
  border: none;
  &.ant-input,
  &.ant-input:focus,
  &.ant-input:focus-within {
    border-color: transparent !important;
    outline: none !important;
    box-shadow: 0px 3.66px 14.65px 0px rgba(0, 0, 0, 0.07);
  }

  &:hover {
    border-color: transparent !important;
    outline: none !important;
    box-shadow: 0px 3.66px 14.65px 0px rgba(0, 0, 0, 0.07);
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #7da1a9 !important;
    color: #7da1a9 !important;

    &::after {
      border-color: #7da1a9 !important;
      border-spacing: 0;
    }
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ButtonAddScriptToFolder = styled(Button)`
  max-width: 324px;
  width: 100%;
  height: 52px;
  border: 2px solid var(--light-description);
  color: var(--light-description);
  background: transparent;
  box-shadow: unset;
  margin-top: 8px;

  &:hover {
    background: transparent;
    color: var(--light-description);
  }

  &:disabled {
    cursor: default;
    background: transparent;
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const StyledSharedSectionWrapper = styled.div`
  background: #f5fbfc;
  width: 100%;
  padding: 16px 8px;
  border-radius: 8px;
`;
