import { useCallback, useState } from 'react';
import { AccountsResource, Pagination, PaginationAccounts } from '../types';
import { getAccountsResources } from '../api/apiWorker';
import { message } from 'antd';

export const useGetAccounts = () => {
  const [accountsResource, setAccountsResource] = useState<AccountsResource[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: null,
    totalPages: 1
  });

  const fetchAccountResources = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAccountsResources();
      const filteredData: AccountsResource[] = !data.length
        ? []
        : data?.map(account => ({
            email: account.email,
            first_name: account.first_name,
            last_name: account.last_name,
            user_id: account.user_id,
            status: account.status
          }));
      setAccountsResource(filteredData || []);
    } catch (e) {
      message.error('Failed to fetch accounts resource');
      console.error('error:', e);
    }
    setLoading(false);
  }, []);

  return {
    accountsResource,
    loading,
    pagination,
    setPagination,
    setAccountsResource,
    fetchAccountResources
  };
};
