import React, { useEffect, useState } from 'react';
import { StyledButton } from '../../assets/styles/Styled';
import { Space, Typography } from 'antd';
import {
  AgreementContainer,
  ButtonsContainer,
  ButtonsWrapper,
  Container,
  Footer,
  FooterImageContainer,
  Header,
  HeaderText,
  PageContent,
  SlideItem,
  SlidesContainer
} from './styled';
import footerImage from '../../assets/images/landing_image.png';
import slide1 from '../../assets/images/slide1.png';
import slide2 from '../../assets/images/slide2.png';
import slide3 from '../../assets/images/slide3.png';
import slide4 from '../../assets/images/slide4.png';
import { getWebConfig } from '../../api/apiWorker';

const slideItems = [
  {
    title: '1. Download',
    description: 'Click the appropriate button for Windows or macOS above to get the latest version',
    image: slide1
  },
  {
    title: '2. Remove Old Version',
    description: 'Uninstall any existing version of the app to ensure a smooth update',
    image: slide2
  },
  {
    title: '3. Locate Installer',
    description: "Open your 'Downloads' folder to find the newly downloaded installation file",
    image: slide3
  },
  {
    title: '4. Install & Enjoy',
    description: 'Double-click the installer and follow the instructions to complete the update',
    image: slide4
  }
];

const LandingPage = () => {
  const [config, setConfig] = useState({
    macOsLink: '',
    windowsLink: '',
    clientVersion: ''
  });

  const getConfig = async () => {
    const config = await getWebConfig();
    setConfig({
      macOsLink: config?.builds_download_urls.macos || '',
      windowsLink: config?.builds_download_urls.windows || '',
      clientVersion: config?.builds_download_urls?.client_version || ''
    });
  };
  useEffect(() => {
    getConfig();
  }, []);
  return (
    <Container>
      <Header>
        <HeaderText>Download the newest</HeaderText>
        <HeaderText>version of mapanda</HeaderText>
      </Header>
      <PageContent>
        <ButtonsContainer>
          <ButtonsWrapper>
            <StyledButton onClick={() => window.open(`${config.windowsLink}`, '_blank')}>
              <Space className="w-100" direction="vertical" size={0}>
                <Typography className="extrabold-16 text-white">Update for Windows</Typography>
                <Typography className="regular-12 violet-solid">Version {config.clientVersion}</Typography>
              </Space>
            </StyledButton>
            <StyledButton className="btn-orange" onClick={() => window.open(`${config.macOsLink}`, '_blank')}>
              <Space className="w-100" direction="vertical" size={0}>
                <Typography className="extrabold-16 text-white">Update for MacOS</Typography>
                <Typography className="regular-12 light-yellow">Version {config.clientVersion}</Typography>
              </Space>
            </StyledButton>
          </ButtonsWrapper>
          <AgreementContainer>
            <Typography className="regular-12 text-secondary">By continuing you agree with</Typography>
            <Space size={4}>
              <a
                href="https://family-fresh.vercel.app/en/privacy-policy"
                rel="noreferrer"
                target="_blank"
                className="regular-12 text-secondary"
              >
                Privacy Policy
              </a>
              <a
                href="https://partners.mapanda.com/en/terms-and-conditions"
                rel="noreferrer"
                target="_blank"
                className="regular-12 text-secondary"
              >
                Term of Use
              </a>
            </Space>
          </AgreementContainer>
        </ButtonsContainer>
        <SlidesContainer>
          {slideItems.map(item => (
            <SlideItem key={item.title}>
              <img alt="slide" src={item.image} />
              <Typography className="extrabold-16 primary-color">{item.title}</Typography>
              <Typography className="normal-14 primary-color">{item.description}</Typography>
            </SlideItem>
          ))}
        </SlidesContainer>
      </PageContent>
      <div className="w-100">
        <FooterImageContainer>
          <img src={footerImage} alt="footerImage" />
        </FooterImageContainer>
        <Footer>
          <Typography className="footer-text">© 2024, made with ♥ by MaPanda</Typography>
        </Footer>
      </div>
    </Container>
  );
};

export default LandingPage;
