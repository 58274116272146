import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Dropdown, Typography } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { FilterOptions, OrganizationItem, SortOptions } from '../../types';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 24px;
`;

const DropdownButton = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: fit-content;
  white-space: nowrap;
  span {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: var(--hint-color) !important;
  }
`;

const MenuItem = styled.p<{ isActive: boolean }>`
  font-size: 16px;
  font-weight: ${props => (props.isActive ? 700 : 500)};
  color: var(--text-secondary);
`;

const sortMenuLabels: { [key in SortOptions]: string } = {
  [SortOptions.newest]: 'Newest first',
  [SortOptions.eldest]: 'Eldest first',
  [SortOptions.az]: 'Sort from A to Z',
  [SortOptions.za]: 'Sort from Z to A'
};

const defaultSortItems = [SortOptions.newest, SortOptions.eldest, SortOptions.az, SortOptions.za];

export interface FilterItems {
  option: FilterOptions | null;
  selectedOrganizations: string[] | null;
}

interface Props {
  onSort: (option: SortOptions) => void;
  onFilterChange: ({ option, selectedOrganizations }: FilterItems) => void;
  organizations: string[];
  defaultSortOption?: SortOptions;
  sortItems?: SortOptions[];
  isShowSortPanel?: boolean;
}

export const FilterPanel: React.FunctionComponent<Props> = ({
  onSort,
  onFilterChange,
  organizations,
  defaultSortOption = SortOptions.newest,
  sortItems = defaultSortItems,
  isShowSortPanel = true
}) => {
  const [sortOption, setSortOption] = useState<SortOptions>(defaultSortOption);
  const [filterOption, setFilterOption] = useState<FilterOptions | null>(FilterOptions.all);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);

  const handleSort = useCallback(
    (option: SortOptions) => {
      setSortOption(option);
      onSort(option);
    },
    [onSort]
  );

  const handleFilter = useCallback(
    (option: FilterOptions) => {
      setSelectedOrganizations([]);
      setFilterOption(option);
      onFilterChange({ option, selectedOrganizations: null });
    },
    [onFilterChange]
  );

  const handleOrganizationSelect = useCallback(
    (orgFilter: string) => {
      setFilterOption(null);
      let updatedList = [...selectedOrganizations];
      if (selectedOrganizations.find(org => org === orgFilter)) {
        updatedList = selectedOrganizations.filter(orgId => orgId !== orgFilter);
      } else {
        updatedList.push(orgFilter);
      }
      setSelectedOrganizations(updatedList);
      if (updatedList.length) {
        onFilterChange({ option: null, selectedOrganizations: updatedList });
      } else {
        onFilterChange({ option: FilterOptions.all, selectedOrganizations: null });
        setFilterOption(FilterOptions.all);
      }
    },
    [setFilterOption, selectedOrganizations, onFilterChange]
  );

  const sortMenuItems = sortItems.map(item => ({
    label: <MenuItem isActive={sortOption === item}>{sortMenuLabels[item]}</MenuItem>,
    key: item,
    onClick: () => handleSort(item)
  }));

  const OrganizationFilters = useMemo(() => {
    return [
      {
        label: (
          <Checkbox className="normal-16 text-secondary" checked={filterOption === FilterOptions.all}>
            Show all
          </Checkbox>
        ),
        key: 'all',
        onClick: () => handleFilter(FilterOptions.all)
      },
      {
        label: (
          <Checkbox className="normal-16 text-secondary" checked={filterOption === FilterOptions.withoutOrganization}>
            Without organization
          </Checkbox>
        ),
        key: 'without',
        onClick: () => handleFilter(FilterOptions.withoutOrganization)
      },
      ...organizations.map(org => ({
        label: (
          <Checkbox className="normal-16 text-secondary" checked={selectedOrganizations.includes(org)}>
            {org}
          </Checkbox>
        ),
        key: org,
        onClick: () => handleOrganizationSelect(org)
      }))
    ];
  }, [filterOption, selectedOrganizations, organizations, handleFilter, handleOrganizationSelect]);

  return (
    <Container className="filter-panel">
      {isShowSortPanel && (
        <Dropdown menu={{ items: sortMenuItems }} trigger={['click']}>
          <DropdownButton className="btn-filter">
            <Typography.Text>{sortMenuLabels[sortOption]}</Typography.Text>
            <CaretDownOutlined />
          </DropdownButton>
        </Dropdown>
      )}

      <Dropdown
        rootClassName="organization-filter-dropdown"
        menu={{ items: OrganizationFilters }}
        trigger={['click']}
        placement="bottomRight"
      >
        <DropdownButton className="btn-filter">
          <Typography.Text className="btn-filter-text">Organization name</Typography.Text>
          <CaretDownOutlined />
        </DropdownButton>
      </Dropdown>
    </Container>
  );
};
