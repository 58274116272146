import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IDeleteResource,
  IMoveScriptsData,
  IMoveScriptToFolderError,
  Pagination,
  Resource,
  ResourceDetail
} from '../../../types';
import { deleteResource, getResource, getResources, moveScripstToFolder } from '../../../api/apiWorker';
import { extractOrganizationsFromScripts } from '../../../helper';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopupKeys } from './usePopups';
import { delay } from '../../../utils';

export type Tabs = 'resents' | 'allFiles' | 'folders';

interface ReturnProps {
  loading: boolean;
  resources: Resource[];
  scriptOrganizations: string[];
  activeTab: Tabs;
  selectedFolder: ResourceDetail | null;
  pagination: Pagination;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setActiveTab: React.Dispatch<React.SetStateAction<Tabs>>;
  fetchResources: () => Promise<void>;
  handleRemove: ({ resourceId, resourceType, deleteChildResources }: IDeleteResource) => Promise<void>;
  handleMoveScripts: ({ folderId, scripts, force_parent_access }: IMoveScriptsData) => Promise<void>;
  setRequestDataMoveFiles: React.Dispatch<React.SetStateAction<IMoveScriptsData | null>>;
  handleBackToAllScripts: () => void;
  handleAggreeMoveScripts: () => void;
}

interface Props {
  togglePopup: ({ popupName, value }: { popupName: PopupKeys; value: boolean | null }) => void;
}

export const UseResource = ({ togglePopup }: Props): ReturnProps => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [scriptOrganizations, setScriptOrganizations] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<Tabs>('allFiles');
  const [selectedFolder, setSelectedFolder] = useState<ResourceDetail | null>(null);
  const [requestDataMoveFiles, setRequestDataMoveFiles] = useState<IMoveScriptsData | null>(null);
  const [search, setSearch] = useState('');
  const [firstRequest, setFirstrequest] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: null,
    totalPages: 1
  });

  const fetchResourcesByParams = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    params.set('page', '1');
    navigate({ search: params.toString() });
    if (page === '1') {
      fetchResources();
    }
  };

  useEffect(() => {
    if (!firstRequest) {
      fetchResourcesByParams();
    }
  }, [search]);

  const fetchResources = async () => {
    const params = new URLSearchParams(location.search);
    const folderId = params.get('folderId');
    const page = params.get('page') || 1;
    setLoading(true);
    try {
      let queryParam = `?page=${page}`;
      if (activeTab === 'folders') {
        queryParam += '&resource_type=folder';
      } else if (folderId) {
        queryParam += `&parent_id=${folderId}`;
      }
      if (search) {
        queryParam += `&search_text=${search}`;
      }
      const resource = await getResources(queryParam);
      if (Array.isArray(resource.data)) {
        setResources(resource.data);
        const totalPages = Math.ceil(resource.total / resource.page_size);
        setPagination(prev => ({ ...prev, page: resource.page, pageSize: resource.page_size, totalPages: totalPages }));
        const organizationList = extractOrganizationsFromScripts(resource.data);
        setScriptOrganizations(organizationList);
      } else {
        setResources([]);
      }
      setFirstrequest(false);
    } catch (e) {
      message.error('Error fetching scripts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, [activeTab, location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const folderId = params.get('folderId');
    if (folderId) {
      handleSetCurrentFolder(folderId);
      return;
    }

    setSelectedFolder(null);
  }, [location.search]);

  const handleRemove = async ({ resourceId, resourceType, deleteChildResources }: IDeleteResource) => {
    setLoading(true);
    try {
      await deleteResource({
        resourceId: resourceId,
        ...(resourceType === 'folder' && { deleteChildResources: deleteChildResources || false }),
        resourceType: resourceType
      });

      if (deleteChildResources !== undefined && selectedFolder) {
        await delay(1000);
        handleBackToAllScripts();
      } else {
        // setResources(prev => prev.filter(resource => resource.resource_id !== resourceId));
        await fetchResources();
      }

      message.success('Script was deleted successfully');
    } catch (e) {
      message.error('Unable to delete script');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAggreeMoveScripts = () => {
    if (!requestDataMoveFiles) return;
    handleMoveScripts({ ...requestDataMoveFiles, force_parent_access: true });
  };

  const handleMoveScripts = async ({ folderId, scripts, force_parent_access }: IMoveScriptsData) => {
    setLoading(true);
    try {
      await moveScripstToFolder({ folderId, scripts, ...(force_parent_access ? { force_parent_access: true } : {}) });
      await fetchResources();
      setRequestDataMoveFiles(null);
    } catch (e) {
      const error: IMoveScriptToFolderError = e as IMoveScriptToFolderError;

      if (error.response?.data?.code === 'resource_access_does_not_match') {
        setRequestDataMoveFiles({
          folderId: folderId,
          scripts: scripts
        });
        togglePopup({
          popupName: 'showPopupErrorAccessSettings',
          value: true
        });
      } else {
        message.error('Something went wrong. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetCurrentFolder = async (folderId: string) => {
    // const folder = await getResource(folderId, `?parent_id=${folderId}`);
    const folder = await getResource(folderId);

    setSelectedFolder(folder);
  };

  const handleBackToAllScripts = () => {
    navigate('', { replace: true });
    setActiveTab('allFiles');
  };

  return {
    resources,
    loading,
    scriptOrganizations,
    activeTab,
    selectedFolder,
    pagination,
    setSearch,
    setActiveTab,
    fetchResources,
    handleRemove,
    handleMoveScripts,
    handleBackToAllScripts,
    handleAggreeMoveScripts,
    setRequestDataMoveFiles
  };
};
