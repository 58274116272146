import styled, { css } from 'styled-components';
import logo from '../logo.png';

export const RootContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--violet-solid);
`;

export const PageWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const PageContainer = styled.div`
  margin-top: 20px;
  padding: 0;
  height: 100%;
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'M PLUS Rounded 1c';
  background: var(--light-gray);
  border-radius: 24px 24px 0 0;
`;

export const PageContent = styled.main`
  width: 100%;
  // max-width: 906px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  @media (max-width: 450px) {
    padding: 0 16px;
  }
`;

export const Logo = styled.div`
  flex-shrink: 0;
  background-image: url(${logo});
  width: 169px !important;
  height: 51px !important;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  font-weight: 700;
  width: 100%;
  height: 100vh;

  & .log-in {
    margin: 0;
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 32px;
  }

  & .divider {
    color: var(--hint-color);
    margin: 16px 0 15px;

    @media (max-width: 1120px) {
      display: none;
    }
  }

  & .terms-of-use-privacy {
    font-size: 12px;
    color: var(--light-blue);
    margin: 0;
    padding-bottom: 4px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;

    & a {
      text-decoration: none;
      color: var(--light-blue);
      margin-left: 2px;
      margin-right: 2px;
      border-bottom: 1px solid var(--light-blue);
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 327px;

  & .error {
    border: 2px solid #ef5a55;
  }
`;

export const EditForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    margin-bottom: 3px;
  }

  & .error {
    border: 2px solid #ef5a55;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  margin-top: 16px;
  position: relative;

  & img {
    position: absolute;
    top: 43px;
    left: 16px;
    width: 16px;
    height: 16px;
  }
`;
export const Input = styled.input`
  padding: 16px;
  border: none;
  outline: none;
  border-radius: 16px;
  background-color: #f0f1f5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:focus {
    outline: var(--primary-color) 2px solid;
  }

  &::placeholder {
    color: #c8c5d3;
  }
`;

export const Button = styled.button`
  margin-top: 24px;
  margin-bottom: 0;
  padding: 10px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 400px;
  font-weight: 700;
  background: linear-gradient(180deg, #ffd08a 0%, #ed6400 100%);
  box-shadow: 0px 3.809px 6.348px 0px rgba(237, 100, 0, 0.33);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  display: inherit;
  align-items: center;
  justify-content: center;
  height: 60px;

  &:hover {
    background: linear-gradient(180deg, #ff8832 0%, #ed6400 100%);
    color: #fff;
    transition: 0.5s ease;
  }

  &:disabled {
    background: #c6c1d2;
    color: var(--text-secondary);
    box-shadow: none;
    cursor: default;
  }
`;

export const SsoButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 1120px) {
    margin-top: 32px;
  }
`;

export const CreateAccountBtnWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: ${props => (props.disabled ? 'light-gray' : 'var(--light-violet)')};
  font-family: 'Open Sans', sans-serif;
  & a {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
  }

  &.btn-script {
    background: var(--light-blue);
  }
`;

export const StyledButton = styled.button`
  color: #ffffff;
  border: none;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 51px;
  width: 100%;
  border-radius: 40px;
  background: var(--gradient-purple);
  box-shadow: 0 3.81px 6.35px 0 rgba(126, 66, 252, 0.33);
  font-size: 16px;
  font-weight: 700;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(110%);
  }

  &.btn-green {
    background: var(--gradient-green);
    box-shadow: 0 3.81px 6.35px 0 rgba(34, 204, 92, 0.33);
  }

  &.btn-orange {
    background: var(--gradient-orange);
    box-shadow: 0 3.809px 6.348px 0 rgba(237, 100, 0, 0.33);
  }

  &.btn-blue {
    background: var(--additional-blue);
    box-shadow: 0 0.876px 3.505px 0 rgba(0, 0, 0, 0.07);
  }

  &:disabled {
    filter: brightness(100%) !important;
    background: var(--second-gray) !important;
    box-shadow: none;
    cursor: not-allowed;
  }
`;

export const ButtonOutlined = styled.button`
  height: 51px;
  width: 100%;
  color: var(--primary-color);
  background: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 40px;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(110%);
  }

  &.btn-orange {
    color: var(--orange-solid);
    border-color: var(--orange-solid);
  }

  &:disabled {
    filter: brightness(100%) !important;
    background: var(--second-gray) !important;
    color: #ffffff;
    border-color: var(--second-gray);
    cursor: not-allowed;
  }
`;

export const AccountItem = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 20px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
  color: #062e3a;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 8px;
`;

export const AccountEmail = styled.span`
  font-size: 16px;
  color: #202225;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AccountDuration = styled.span`
  font-size: 14px;
  color: #a4a6b3;
  margin-left: 8px;
`;

export const TermsOfUsePrivacyPolicyWrapper = styled.div`

  & .terms-of-use-privacy {
    font-size: 12px;
    color: #A29EB6;
    margin: 0;
    padding-bottom: 4px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;

    & a {
      text-decoration: none;
      color: #A29EB6;
      margin-left: 2px;
      margin-right: 2px;
      border-bottom: 1px solid #A29EB6;
    }
`;

export const LoaderWrapperModal = styled.div`
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  background: rgba(2, 17, 26, 0.55);
  z-index: 9999;
`;

export const LoaderContent = styled.div`
  background: #ffffff;
  display: flex;
  width: 158px;
  height: 158px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #713fdc;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 16px;
`;

export const Status = styled.p`
  color: #ef5a55;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const DropDownWrapper = styled.ul`
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 3.75px 15px 0px rgba(0, 0, 0, 0.07);
  list-style-type: none;
  color: var(--light-blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  z-index: 2;
  width: 176px;
  top: 0px;
  right: 10px;

  & li {
    margin-bottom: 24px;
  }

  & li.activate {
    color: #56c323;
  }

  & li:last-child {
    color: #ef5a55;
    margin-bottom: 0px;
  }
`;

export const PopUpWrapperDelete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  background: rgba(2, 17, 26, 0.55);
  z-index: 9999;
  top: 0;
  left: 0;
`;

export const PopUpContentDelete = styled.div`
  background: #ffffff;
  display: flex;
  width: 335px;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 16px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  & .title {
    color: #0b3d4c;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 120px;
    margin-bottom: 18px;
  }
  & .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  & .edit-form-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 15px;

    & span {
      margin-top: 5px;
    }

    & input[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  & input.confirm {
    border: none;
    color: #062e3a;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 12.21px;
    padding: 0 16px;
    background: var(--background-colors-bg-white, #fff);
    box-shadow: 0px 3.663px 14.652px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    height: 51px;
    & span {
      font-weight: 700;
    }
  }
`;

export const DeleteBtn = styled.button`
  display: flex;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 97.678px;
  background: rgba(239, 90, 85, 0.2);
  color: #ef5a55;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 296px;
  height: 48px;
  cursor: pointer;
  &:hover {
    background: #ef5a55;
    color: #fff;
  }
`;

export const EditBtn = styled.button`
  display: flex;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 97.678px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 296px;
  height: 48px;
  cursor: pointer;
  background: linear-gradient(180deg, #fae9cf 0%, #ed995c 100%);
  &:hover {
    background: #ed6400;
    color: #fff;
  }
  &:disabled {
    background: #c6c1d2;
    color: var(--light-blue);
    cursor: default;
    box-shadow: none;
  }
`;

export const RegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background: #ffffff;
  height: 100%;
  width: 100%;
  max-width: 350px;
  padding: 12px;
  margin: auto;
  & .new-user {
    margin: 0;
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 32px;
    color: #231b45;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & .success {
    margin: 0;
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 32px;
    color: #56c323;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 326px;
    text-align: center;
  }

  & a {
    text-decoration: none;
  }
`;

export const BackToMainBtn = styled.button`
  padding: 10px;
  color: #ff8832;
  cursor: pointer;
  border-radius: 400px;
  font-weight: 700;
  background: #fff;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  display: inherit;
  align-items: center;
  justify-content: center;
  height: 51px;
  border: 2px solid #ff8832;
  gap: 40px;
  flex-shrink: 0;
  width: 100%;

  &:hover {
    background: linear-gradient(180deg, #ff8832 0%, #ed6400 100%);
    color: #fff;
    transition: 0.5s ease;
  }
`;

export const ErrorMsgSm = styled.span`
  color: #ff3131;
  font-size: 14px;
  text-align: left;
`;

export const FooterButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 132px;
  padding: 24px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
`;

export const inputStyles = css`
  font-family: 'Mulish', sans-serif !important;
  min-height: 51px !important;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 3.45px 13.79px 0px rgba(0, 0, 0, 0.07);
  color: #062e3a !important;
`;

export const StyledLabel = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  text-transform: capitalize;
  color: #3d6c77;
  & .tag-email {
    font-size: 12px;
    font-weight: 500;
    line-height: 15.06px;
    text-transform: none;
    color: #7da1a9;
  }
`;

export const SharingButton = styled.button`
  position: relative;
  font-family: 'Mulish', sans-serif !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  min-width: 183px;
  border: 2px solid transparent;
  height: 51px;
  padding: 0px 12px;
  border-radius: 8px;
  background: #d0e3e7;
  color: #7da1a9;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    border: 2px solid #afccd2;
    background: transparent;
    color: #afccd2;
  }

  @media (max-width: 768px) {
    min-width: unset;
    width: fit-content;
  }
`;

export const StyledCustomFormTag = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 5px 2px 0;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  height: 51px;
  box-shadow: 0px 3.81px 15.25px 0px #00000012;
  cursor: default;

  & .btn-close:hover {
    cursor: pointer;
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const AccountTableWrapper = styled.div`
  height: calc(100vh - 147px - 50px - 86px - 100px);
  overflow-y: auto;
  @media (max-width: 1120px) {
    height: calc(100vh - 147px - 50px - 86px - 160px);
  }
  @media (max-width: 800px) {
    height: calc(100vh - 147px - 50px - 86px - 220px);
  }
`;
