import { useCallback } from 'react';
import { useScriptForm } from '../ScriptCreateStore';
import { message } from 'antd';
import { IAccessControl, LoItemToPreview, ResourceCreatePayload, Scenario, ScriptCreatePayload, Sections } from '../../../types';
import { createResource, updateResource } from '../../../api/apiWorker';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePreview } from '../../../context/PreviewContext';

const updateActions = (scenario: Scenario[], loObjects: LoItemToPreview): Scenario[] => {
  return scenario.map(chapter => ({
    ...chapter,
    blocks: chapter.blocks.map(block => {
      const updatedActions = block.actions?.map(action => {
        if (action.action_type === 'live_object' && action.uniq_id) {
          const updatedItem = loObjects[block.id]?.find(item => item.uniq_id === action.uniq_id);
          if (updatedItem) {
            return {
              ...action,
              position_x: updatedItem.position_x,
              position_y: updatedItem.position_y,
              size: updatedItem.size
            };
          }
        }

        if (action.id === 'delete_all_live_objects') {
          return {
            action_type: action.id,
            group: action.group,
            id: action.id
          };
        }

        return action;
      });

      return {
        ...block,
        actions: updatedActions
      };
    })
  }));
};

export const useSubmit = () => {
  const navigate = useNavigate();
  const { formValues, setLoading, setBlocked, setSectionErrors, sharingList } = useScriptForm();
  const { loToPreview } = usePreview();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const parentId = params.get('parentId');

  return useCallback(async () => {
    setSectionErrors({ section: null, errorId: null });

    const missingScenarioTitleIndex = formValues.scenario.findIndex(value => !value.title);
    if (missingScenarioTitleIndex !== -1) {
      setSectionErrors({ section: Sections.Scenario, errorId: missingScenarioTitleIndex });
      return message.error(`Scenario chapter #${missingScenarioTitleIndex + 1} is missing title`);
    }
    const missingScenarioBlockIndex = formValues.scenario.findIndex(value => {
      return value.blocks.find(block => block.type === 'replica' && !block.content);
    });

    if (missingScenarioBlockIndex !== -1) {
      setSectionErrors({ section: Sections.Scenario, errorId: missingScenarioBlockIndex });
      return message.error(`Scenario chapter #${missingScenarioBlockIndex + 1} is missing content`);
    }
    const missingScenarioNoteBlockIndex = formValues.scenario.findIndex(value => {
      return value.blocks.find(block => block.type === 'note' && !block.actions?.length && !block.content);
    });
    if (missingScenarioNoteBlockIndex !== -1) {
      setSectionErrors({ section: Sections.Scenario, errorId: missingScenarioNoteBlockIndex });
      return message.error(`Scenario chapter #${missingScenarioNoteBlockIndex + 1} is missing action or content`);
    }
    const scenarioValues = updateActions(formValues.scenario, loToPreview);
    const accessControlUsers: IAccessControl[] = sharingList.users.map(item => ({
      entity_id: item.value,
      entity_type: 'user',
      write_access: item.restriction === 'edit'
    }));
    const accessControlOrganizations: IAccessControl[] = sharingList.organizations.map(item => ({
      entity_id: item.value,
      entity_type: 'organization',
      write_access: item.restriction === 'edit'
    }));
    const { users, organizations, ...restFormValues } = formValues;
    const scriptData: ResourceCreatePayload = {
      ...restFormValues,
      scenario: scenarioValues,
      access_control: [...accessControlUsers, ...accessControlOrganizations],
      copy_allowed: formValues.copy_allowed,
      resource_type: 'script',
      ...(parentId && { parent_id: parentId })
    };

    setBlocked(false);
    setLoading(true);
    try {
      if (formValues.resource_id) {
        await updateResource(scriptData);
        message.success('Script was successfully updated');
      } else {
        await createResource(scriptData);
        message.success('Script was successfully created');
      }
    } catch (e) {
      if (formValues.resource_id) {
        message.error('Unable to update Script');
      } else {
        message.error('Unable to create Script');
      }
      console.error('error', e);
    } finally {
      setLoading(false);
      parentId ? navigate(`/scripts?folderId=${parentId}`) : navigate('/scripts');
    }
  }, [formValues, setLoading, navigate, setBlocked, loToPreview, sharingList]);
};
