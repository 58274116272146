import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Scenario, Sections, SelectFormItem } from '../../../types';
import { isEqual } from 'lodash';
import usePrompt from '../../../hooks/usePrompt';
import { usePreview } from '../../../context/PreviewContext';
import { IFieldValue } from '../../../components/SharedForm/SharedForm';

interface SectionError {
  section: Sections | null;
  errorType?: string | null;
  errorId?: string | number | null;
}

interface ScriptFormValues {
  slides: string[];
  live_objects: string[];
  resource_name: string;
  users: SelectFormItem[];
  organizations: SelectFormItem[];
  resource_id: string;
  scenario: Scenario[];
  background: string;
  copy_allowed: boolean;
}

export interface ISharingList {
  users: IFieldValue[];
  organizations: IFieldValue[];
}

export interface IBlockedSharingSection {
  isBlocked: boolean;
  isCallback: boolean;
}
interface ContextInterface {
  formValues: ScriptFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ScriptFormValues>>;
  blocked: boolean;
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>;
  readOnlyMode: boolean;
  setReadOnlyMode: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  submitDisabled: boolean;
  initialValuesRef: React.MutableRefObject<ScriptFormValues>;
  setInitialValues: (values: ScriptFormValues) => void;
  sectionErrors: SectionError;
  setSectionErrors: React.Dispatch<React.SetStateAction<SectionError>>;
  setSharingList: React.Dispatch<React.SetStateAction<ISharingList>>;
  sharingList: ISharingList;
  setCurrentSection: React.Dispatch<React.SetStateAction<Sections>>;
  currentSection: Sections;
}

export const initialScriptFormValues: ScriptFormValues = {
  slides: [],
  resource_name: '',
  users: [],
  organizations: [],
  resource_id: '',
  live_objects: [],
  scenario: [],
  background: '',
  copy_allowed: false
};

const initialSectionError: SectionError = {
  section: null,
  errorType: null,
  errorId: null
};

const initialSharingList = {
  users: [],
  organizations: []
};

const ScriptFormContext = createContext<ContextInterface>({
  formValues: initialScriptFormValues,
  initialValuesRef: { current: initialScriptFormValues },
  setFormValues: () => {},
  blocked: false,
  setBlocked: () => {},
  setReadOnlyMode: () => {},
  readOnlyMode: false,
  setLoading: () => {},
  loading: false,
  submitDisabled: true,
  setInitialValues: () => {},
  sectionErrors: initialSectionError,
  setSectionErrors: () => {},
  setSharingList: () => {},
  setCurrentSection: () => {},
  sharingList: initialSharingList,
  currentSection: Sections.NameAndShare
});

export const ScriptFormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formValues, setFormValues] = useState<ScriptFormValues>(initialScriptFormValues);
  const [sectionErrors, setSectionErrors] = useState<SectionError>(initialSectionError);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState<Sections>(Sections.NameAndShare);

  const [sharingList, setSharingList] = useState<ISharingList>(initialSharingList);
  const initialValuesRef = useRef<ScriptFormValues>(initialScriptFormValues);

  const [blocked, setBlocked] = useState(false);
  const { loToPreview, loInitialFormValues } = usePreview();
  usePrompt(blocked);
  const getTransformValues = useCallback(
    () => ({
      transformFormValues: {
        ...formValues,
        users: sharingList.users.map(item => ({
          value: item.value,
          restriction: item.restriction
        })),
        organizations: sharingList.organizations.map(item => ({
          value: item.value,
          restriction: item.restriction
        }))
      },
      transformInitialValuesRef: {
        ...initialValuesRef.current,
        users: initialValuesRef.current.users.map(item => ({
          value: item.value,
          restriction: item.restriction
        })),
        organizations: initialValuesRef.current.organizations.map(item => ({
          value: item.value,
          restriction: item.restriction
        }))
      }
    }),
    [formValues, sharingList, initialValuesRef]
  );

  useEffect(() => {
    const { transformFormValues, transformInitialValuesRef } = getTransformValues();
    setBlocked(
      !!formValues.users.length || !!formValues.organizations.length || !isEqual(transformFormValues, transformInitialValuesRef)
    );
  }, [getTransformValues, formValues]);

  const submitDisabled = useMemo(() => {
    const { transformFormValues, transformInitialValuesRef } = getTransformValues();
    const loToPreviewKeys = Object.keys(loToPreview);
    const loInitialFormValuesKeys = Object.keys(loInitialFormValues);
    if (loToPreviewKeys.length && loInitialFormValuesKeys.length) {
      return (
        loading ||
        !transformFormValues.resource_name ||
        (isEqual(transformFormValues, transformInitialValuesRef) && isEqual(loToPreview, loInitialFormValues))
      );
    } else {
      return loading || !transformFormValues.resource_name || isEqual(transformFormValues, transformInitialValuesRef);
    }
  }, [getTransformValues, loading, loToPreview]);

  const setInitialValues = useCallback((values: ScriptFormValues) => {
    initialValuesRef.current = values;
  }, []);

  return (
    <ScriptFormContext.Provider
      value={{
        formValues,
        setFormValues,
        blocked,
        setBlocked,
        readOnlyMode,
        setReadOnlyMode,
        loading,
        setLoading,
        submitDisabled,
        initialValuesRef,
        setInitialValues,
        sectionErrors,
        setSectionErrors,
        setSharingList,
        sharingList,
        currentSection,
        setCurrentSection
      }}
    >
      {children}
    </ScriptFormContext.Provider>
  );
};

export const useScriptForm = () => useContext(ScriptFormContext);
